/**
 * Consent Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ConsentPageBackButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  marginTop: "1rem",
  padding: theme.spacing(1),
  color: "black",
  boxShadow: "none",
}));

export const ExitItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginTop: "1rem",
  padding: theme.spacing(1),
  fontSize: "1rem",
  boxShadow: "none",
  alignContent: "right",
  justifyContent: "end",
}));
export const ConsentPageButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  boxShadow: "none",
}));
export const ConsentPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
}));

export const ConsentPageItemConsentNotice = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  paddingTop: "3rem",
  paddingLeft: "10rem",
  fontSize: "1.5rem",
  color: "#00b4d8",
  fontFamily: "roboto",
  fontWeight: "bold",
  boxShadow: "none",
}));
export const ConsentPageItemContent = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  color: "black",
  boxShadow: "none",
  paddingLeft: "10rem",
  paddingRight: "10rem",
  fontSize: "1.5rem",
  fontFamily: "roboto",
  fontWeight: 300,
}));
export const consentPageStyles = {
  mainBox: { flexGrow: 1, background: "white" },
  tuLogo: {
    position: "absolute",
    height: "7vh",
  },
};

export const ConsentButton = styled(Button)(({ theme }) => ({
  minWidth: "10rem",
  minHeight: "5rem",
  fontSize: "2rem",
}));

export const BackButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "7rem",
  height: "3rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#00b4d8",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
}));
