/**
 * single api call  styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const singleApiCallStyles = {
  callHeader: {
    fontSize: "1.25rem",
    fontFamily: "Oswald",
    color: "#1e6091",
  },
  callDetails: {
    fontSize: "1.25rem",
    fontFamily: "Oswald",
    color: "#1e6091",
  },
  methodType: {
    fontSize: "1.25rem",
    fontFamily: "Oswald",
    color: "white",
  },
  bodyHeader: {
    fontSize: "1rem",
    fontFamily: "Oswald",
    color: "#1e6091",
  },
  required: {
    fontSize: "0.75rem",
    fontFamily: "Oswald",
    color: "red",
  },
  statusExplanation: {
    marginTop: "12rem",
    fontSize: "0.85rem",
    fontFamily: "Oswald",
    color: "#1e6091",
  },
  statusGreen: {
    fontSize: "3rem",
    fontFamily: "Oswald",
    color: "green",
  },
  statusRed: {
    fontSize: "3rem",
    fontFamily: "Oswald",
    color: "red",
  },
};

export const CustomApiAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `1px solid white`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&(:first-of-type)": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
  textAlign: "left",
}));

export const CustomApiAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#1e6091" }} />
    }
    {...props}
  />
))(() => ({
  backgroundColor: "#f2f9ff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "2rem",
  },
}));

export const CustomApiAccordionDetails = styled(MuiAccordionDetails)(() => ({
  //   padding: "1rem",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#f2f9ff",
}));
