/**
 * Flow Page Context
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { createContext } from "react";

export const FlowPageContext = createContext({
  showAddFlowModal: false,
  updateShowAddFlowModal: () => {},
});
