/**
 * Agency Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button } from "@mui/material";

//styles
import { Item as NewItem } from "./AgencyWelcomePage.styles";

//component imports
import AgencyHeader from "../../components/AgencyHeader/AgencyHeader";
import { Footer } from "../../components/Footer/Footer";

//context
import { FlowContext } from "../../context/FlowContext";
import { LoginContext } from "../../context/LoginContext";
import { AgencySignIn } from "../../components/AgencySignIn/AgencySignIn";
import {
  getNextPageLink,
  getSSMParams,
} from "../../utils/commonFunctions/commonFunctions";

const AgencyWelcomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedFlow, flowInPlayConfig } = useContext(FlowContext);
  const { apiDetails, updateApiDetails } = useContext(LoginContext);
  const nextPageLink = getNextPageLink(flowInPlayConfig, location.pathname);
  const { action, flowType } = useParams();

  const [agencyName, setAgencyName] = useState("");

  useEffect(() => {
    setAgencyName(selectedFlow.flowAgency.name);
  }, [selectedFlow]);

  const tuClicked = async () => {
    // Get api credentials from the aws ssm parameter store
    // and store them in the login context to be available
    // for making TruValidate calls in subsequent pages
    try {
      const credentials = await Auth.currentCredentials();
      const idmv_otp_creds = await getSSMParams(
        "/amplify/d1jdqdt8nkfs9z/dev/IDMV_OTP",
        credentials
      );
      updateApiDetails({ idmv_otp_details: { ...idmv_otp_creds } });
    } catch (error) {
      console.error("Error setting api credentials", error);
    }

    navigate(`${nextPageLink}`, { replace: true });
  };

  return (
    <div className="pageWrapper">
      <div className="pageHeader">
        <AgencyHeader />
      </div>
      <div className="pageBody">
        <NewItem>
          <AgencySignIn
            handleSubmit={tuClicked}
            action={action}
            flowType={flowType}
          />
        </NewItem>
      </div>
      <div className="pageFooter">
        <Footer />
      </div>
    </div>
  );
};
export default AgencyWelcomePage;
