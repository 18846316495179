/**
 * Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import OtpInput from "react-otp-input";
//styling
import { MfaItem as Item } from "./Mfa.styles";
//global context
import { LoginContext } from "../../context/LoginContext";
//services
import { confirmSignInMFA } from "../../services/Authentication";
import { isEmpty } from "../../utils/commonFunctions/commonFunctions";

export const Mfa = () => {
  const { currentUserObject, updateCurrentUserObject, updateUserLoggedIn } =
    useContext(LoginContext);
  const [otp, setOtp] = useState("");
  const [loggedInUser, setLoggedInUser] = useState({});

  const handleChange = (newOTP) => {
    setOtp(newOTP);
  };

  useEffect(() => {
    const confirm = async () => {
      if (otp.length === 6 && isEmpty(loggedInUser)) {
        let loggedUser = await confirmSignInMFA(currentUserObject, otp);
        if (loggedUser !== undefined) {
          setLoggedInUser(loggedUser);
          updateUserLoggedIn();
        }
      }
    };

    confirm();
  }, [otp, currentUserObject, updateUserLoggedIn, loggedInUser]);

  useEffect(() => {
    if (!isEmpty(loggedInUser)) {
      updateCurrentUserObject(loggedInUser);
    }
  }, [loggedInUser, updateCurrentUserObject]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "2rem",
      }}
    >
      <Item>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span>-</span>}
          containerStyle={{
            maxWidth: "30rem",
          }}
          inputStyle={{
            border: "2px solid black",
            borderRadius: "8px",
            width: "2.5rem",
            height: "2.5rem",
            fontSize: "1.5rem",
            margin: "0.5rem",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
            marginLeft: "1rem",
          }}
        />
      </Item>
    </Box>
  );
};
