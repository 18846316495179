/**
 * Api Calls Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
//components
import BodyBox from "../BodyBox/BodyBox";
import ParameterResponses from "../ParemeterResponses/ParameterResponses";
//styles
import {
  CustomApiAccordion as Accordion,
  CustomApiAccordionDetails as AccordionDetails,
  CustomApiAccordionSummary as AccordionSummary,
  singleApiCallStyles,
} from "./SingleApiCall.styles";

const SingleApiCall = ({ call }) => {
  const [expanded, setExpanded] = useState(false);
  const docVerificationURL = "https://app.trustev.com/api/v2.0";
  const [bodyExists, setBodyExists] = useState(false);
  const [headerExists, setHeaderExists] = useState(false);
  const fullUrl =
    call.endpointUrl ?? docVerificationURL + "/" + call.endpointName;

  useEffect(() => {
    if (Object.keys(call.body).length > 0) setBodyExists(true);
    else setBodyExists(false);
    if (Object.keys(call.headers).length !== 0) setHeaderExists(true);
    else setHeaderExists(false);
  }, [call]);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary id={call.endpointName}>
        <Stack direction="row">
          <Box
            sx={{
              borderRadius: "0.25rem",
              height: "2rem",
              width: "5rem",
              background: call.methodType === "POST" ? "green" : "#00b4d8",
              marginLeft: "0.5rem",
              marginRight: "1rem",
              textAlign: "center",
            }}
          >
            <Typography sx={singleApiCallStyles.methodType}>
              {call.methodType}
            </Typography>
          </Box>
          <Typography sx={singleApiCallStyles.callHeader}>
            /{call.endpointName}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            backgroundColor: "white",
            padding: "0.5rem",
            marginBottom: "1rem",
            borderRadius: "0.5rem",
          }}
        >
          <Typography sx={singleApiCallStyles.callDetails}>
            {fullUrl}
          </Typography>
        </Box>
        <ParameterResponses typeOfHeader="params" />

        {bodyExists ? (
          <Stack
            direction="row"
            spacing={15}
            sx={{ marginBottom: "1rem", marginLeft: "1rem" }}
          >
            <Stack direction="row">
              <Typography sx={singleApiCallStyles.bodyHeader}>Body</Typography>
              <Typography sx={singleApiCallStyles.required}>
                *required
              </Typography>
            </Stack>
            <BodyBox body={call.body} />
          </Stack>
        ) : (
          <></>
        )}
        {headerExists ? (
          <Stack direction="row" spacing={15}>
            <Stack direction="row">
              <Typography sx={singleApiCallStyles.bodyHeader}>
                Headers
              </Typography>
              <Typography sx={singleApiCallStyles.required}>
                *required
              </Typography>
            </Stack>
            <BodyBox body={call.headers} />
          </Stack>
        ) : (
          <></>
        )}
        <ParameterResponses typeOfHeader="responses" />
        <Stack direction="row" spacing={10}>
          <Box sx={{ width: "8rem" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography
                sx={
                  call.status === 200
                    ? singleApiCallStyles.statusGreen
                    : singleApiCallStyles.statusRed
                }
              >
                {call.status}
              </Typography>
              <Typography sx={singleApiCallStyles.statusExplanation}>
                {call.statusExplanation}
              </Typography>
            </Stack>
          </Box>

          <BodyBox body={call.endpointResponse} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SingleApiCall;
