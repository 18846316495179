export const dashboardCardStyles = {
  header: {
    fontSize: "3rem",
    marginTop: "5.5rem",
    fontWeight: 600,
    fontFamily: "Oswald",
  },
  body: {
    fontSize: "1.5rem",
    marginTop: ".5rem",
    fontWeight: 400,
    fontFamily: "Oswald",
  },
};
