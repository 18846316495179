/**
 * Dashboard Card Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//library imports
import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

//styles
import { dashboardCardStyles } from "./DashboardCard.styles";

const DashboardCard = ({ cardStyle, icon, header, body }) => {
  return (
    <Card sx={cardStyle}>
      <CardContent>
        {" "}
        {icon}
        <Typography sx={dashboardCardStyles.header}> {header}</Typography>
        <Typography sx={dashboardCardStyles.body}> {body}</Typography>
      </CardContent>
    </Card>
  );
};
export default DashboardCard;
