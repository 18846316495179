/**
 * Success page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";


export const Item = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));


export const agencyWelcomeStyles = {
  irsLogoStyle: {
    color: "white",
    marginTop: "0.5rem",
    height: 64,
    marginLeft: "15rem",
  },
  boxTextStyle: {
    flexGrow: 1,
    fontFamily: "Source Sans Pro",
    fontSize: "1rem",
    lineHeight: "3.5rem",
    fontWeight: 400,
    color: "#1b1b1b",
  },
};

export const AgencyWelcomeItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  boxShadow: "none",
}));
export const AgencyWelcomeOrItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  boxShadow: "none",
  width: "20rem",
  marginTop: "1rem",
}));
export const BorderLine = styled(Paper)(({ theme }) => ({
  flexBasis: "auto",
  borderBottom: "1px solid #000",
  flex: 1,
  content: '""',
}));

export const AgencySignUpButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "22rem",
  height: "4rem",
  backgroundColor: "#ffe246",
  fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  "&:hover": {
    backgroundColor: "#fff75e",
  },
  fontSize: "1.5rem",
  border: "3px solid #ffe246 !important",
}));

export const AgencySignInIdMeButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "22rem",
  height: "4rem",
  backgroundColor: "#FCD800",
  "&:hover": {
    backgroundColor: "#fff75e",
  },
  fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  border: "3px solid #08833D !important",
}));

export const AgencySignInButton = styled(Button)(({ theme }) => ({
  marginTop: "0.5rem",
  color: "white",
  width: "22rem",
  height: "4rem",
  backgroundColor: "#00599C",
  "&:hover": {
    backgroundColor: "#00599C",
  },
  fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  fontWeight: 700,
}));
