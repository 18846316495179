import { QRCodeSVG } from "qrcode.react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { StyledBox, QRCodeBox } from "./QRCode.styles";

export function QRCode(props) {
  const { qrCode } = props;

  return (
    <QRCodeBox>
      {qrCode ? (
        <QRCodeSVG value={qrCode} size={300} />
      ) : (
        <StyledBox>
          <CircularProgress />
          <Typography variant="h4">Loading QR Code</Typography>
        </StyledBox>
      )}
    </QRCodeBox>
  );
}
