import { StyledCard, StyledCardHeader } from "./IdVerInputDisplayTable.styles";
import {
  Avatar,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import KeyboardIcon from "@mui/icons-material/Keyboard";

export function IdVerInputDisplayTable(props) {
  const { demoUserInfo } = props;
  return (
    <StyledCard>
      <StyledCardHeader
        title="Fields Entered by You"
        avatar={
          <Avatar sx={{ bgcolor: blue[500] }}>
            <KeyboardIcon />
          </Avatar>
        }
      />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>{demoUserInfo.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>{demoUserInfo.lastName}</TableCell>
            </TableRow>
            {demoUserInfo.addressLine1 && (
              <TableRow>
                <TableCell>Address Line 1</TableCell>
                <TableCell>{demoUserInfo.addressLine1}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.addressLine2 && (
              <TableRow>
                <TableCell>Address Line 2</TableCell>
                <TableCell>{demoUserInfo.addressLine2}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.city && (
              <TableRow>
                <TableCell>City</TableCell>
                <TableCell>{demoUserInfo.city}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.state && (
              <TableRow>
                <TableCell>State</TableCell>
                <TableCell>{demoUserInfo.state}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.zip && (
              <TableRow>
                <TableCell>Zipcode</TableCell>
                <TableCell>{demoUserInfo.zip}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.phone && (
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>{demoUserInfo.phone}</TableCell>
              </TableRow>
            )}
            {demoUserInfo.ssn && (
              <TableRow>
                <TableCell>SSN</TableCell>
                <TableCell>
                  {"***-**-" + demoUserInfo.ssn.substring(5)}
                </TableCell>
              </TableRow>
            )}
            {demoUserInfo.dob && (
              <TableRow>
                <TableCell>Date of Birth</TableCell>
                <TableCell>{demoUserInfo.dob}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </StyledCard>
  );
}
