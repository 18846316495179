/**
 * Flows Table  component
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
export const flowTableStyles = {
  tableStyles: {
    boxShadow: "none",
    "& .MuiTableRow-root:hover": {
      cursor: "pointer",
    },
  },
  flowLastDemoDate: {
    fontFamily: "Oswald",
    fontSize: "1rem",
    color: "#6c757d",
  },
};
