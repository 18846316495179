/**
 * Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Typography, Checkbox, Stack } from "@mui/material";
//mui icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import KeyIcon from "@mui/icons-material/Key";
//styling
import {
  NewPasswordItem as Item,
  NewPasswordButtonItem,
  NewPasswordButton,
  NewPasswordInputField,
  newPassStyles,
} from "./NewPassword.styles";
//global context
import { LoginContext } from "../../context/LoginContext";
//services
import { changePasswordFirstTime } from "../../services/Authentication";

export const NewPassword = ({ attemptedUsername, attemptedPass }) => {
  let navigate = useNavigate();
  const { updateUserMustChangePassword, userPhoneNumber } =
    useContext(LoginContext);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleChangePass1 = (event) => {
    let newPassword1 = event.target.value;
    setPassword1(newPassword1);
  };
  const handleChangePass2 = (event) => {
    let newPassword2 = event.target.value;
    setPassword2(newPassword2);
  };

  const newPassButtonClicked = () => {
    if (password1 === password2) {
      let updatedUser = changePasswordFirstTime(
        attemptedUsername,
        attemptedPass,
        password1
      );
      if (updatedUser !== null) {
        updateUserMustChangePassword();
        navigate("../", { replace: true });
      }
    }
  };

  return (
    <Box alignItems="center" justify="center" sx={{ paddingTop: "2rem" }}>
      <Item>
        <FormControl variant="outlined">
          <NewPasswordInputField
            color="secondary"
            id="outlined-adornment-password"
            type={showPassword1 ? "text" : "password"}
            value={password1}
            onChange={handleChangePass1}
            sx={newPassStyles.usernamePasswordText}
            startAdornment={
              <InputAdornment
                position="start"
                sx={newPassStyles.usernamePasswordIcons}
              >
                <KeyIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={newPassStyles.usernamePasswordIcons}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                  edge="end"
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Item>
      <Item>
        <FormControl variant="outlined">
          <NewPasswordInputField
            color="secondary"
            id="outlined-adornment-password"
            type={showPassword2 ? "text" : "password"}
            value={password2}
            onChange={handleChangePass2}
            sx={newPassStyles.usernamePasswordText}
            startAdornment={
              <InputAdornment
                position="start"
                sx={newPassStyles.usernamePasswordIcons}
              >
                <KeyIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={newPassStyles.usernamePasswordIcons}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Item>
      <Item>
        <Stack direction="row">
          <Checkbox defaultChecked />
          <Typography
            sx={{
              color: " #caf0f8",
              fontFamily: "Oswald",
              fontSize: "0.75rem",
              textAlign: "center",
              paddingRight: "5rem",
              paddingTop: "0.5rem",
            }}
          >
            The phone number we have on file for you is {userPhoneNumber}. I
            consent to recieving SMS text messages for the purpose of two factor
            authentication at this number
          </Typography>
        </Stack>
      </Item>
      <NewPasswordButtonItem>
        <NewPasswordButton variant="contained" onClick={newPassButtonClicked}>
          Update Password
        </NewPasswordButton>
      </NewPasswordButtonItem>
    </Box>
  );
};
