/**
 * Flow Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const flowPageStyles = {
  mainBox: { flexGrow: 1, background: "white" },
  addFlowButton: {
    background: "#219ebc",
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    width: "10rem",
    color: "white",
    "&:hover": {
      background: "#023047",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
};

export const FlowPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "center",
  color: "#013a63",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  marginLeft: "2rem",
  height: "100vh",
  marginTop: "2rem",
  boxShadow: "none",
}));

export const FlowPageItemAddFlow = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "right",
  marginTop: "1rem",
  height: "2.5rem",
  color: "#013a63",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  boxShadow: "none",
}));

export const FlowPageItemContent = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(2),
  color: "black",
  boxShadow: "none",
  paddingLeft: "10rem",
  paddingRight: "10rem",
  fontSize: "1.5rem",
  fontFamily: "roboto",
  fontWeight: 300,
}));
