/**
 * Success Config
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import AgencyWelcomePage from "../pages/AgencyWelcomePage/AgencyWelcomePage";
import ConfirmInfoPage from "../pages/ConfirmInfoPage/ConfirmInfoPage";
import DocVerApiPage from "../pages/DocVerApiPage/DocVerApiPage";
import SsnPhonePage from "../pages/SsnPhonePage/SsnPhonePage";
import OtpPage from "../pages/OtpPage/OtpPage";
import SuccessPage from "../pages/SuccessPage/SuccessPage";
import IdVerApiPage from "../pages/IdVerApiPage/IdVerApiPage";

export const successConfig = (flowId) => {
  return [
    {
      route: `AgencyWelcome/:flowId/:action`,
      component: (
        <AgencyWelcomePage
          nextPageLink={`../DocVerify/${flowId}`}
          previousPageLink={`../Dashboard`}
        />
      ),
    },
    {
      route: `DocVerify/:flowId`,
      component: (
        <DocVerApiPage
          nextPageLink={`../IdVerify/${flowId}`}
          previousPageLink={`../AgencyWelcome/${flowId}/register`}
        />
      ),
    },
    {
      route: `IdVerify/:flowId`,
      component: (
        <IdVerApiPage
          nextPageLink={`../Otp/${flowId}`}
          previousPageLink={`../DocVerify/${flowId}`}
        />
      ),
    },
    {
      route: `SsnPhone/:flowId`,
      component: (
        <SsnPhonePage
          nextPageLink={`../ConfirmInfo/${flowId}`}
          previousPageLink={`../DocVerify/${flowId}`}
        />
      ),
    },
    {
      route: `ConfirmInfo/:flowId`,
      component: (
        <ConfirmInfoPage
          nextPageLink={`../Otp/${flowId}`}
          previousPageLink={`../SsnPhone/${flowId}`}
        />
      ),
    },
    {
      route: `Otp/:flowId`,
      component: (
        <OtpPage
          nextPageLink={`../Success/${flowId}`}
          previousPageLink={`../IdVerify/${flowId}`}
        />
      ),
    },
    {
      route: `Success/:flowId`,
      component: <SuccessPage previousPageLink={`../Otp/${flowId}`} />,
    },
  ];
};

export const getFlowPaths = (flowId, flowPath) => {
  let flowPaths = [];
  switch (+flowPath) {
    case 1:
      flowPaths = getDocVerOnlyPaths(flowId, flowPath);
      break;
    case 2:
      flowPaths = getIdVerOnlyPaths(flowId, flowPath);
      break;
    case 3:
      flowPaths = getOtpOnlyPaths(flowId, flowPath);
      break;
    case 4:
      flowPaths = getDocIdOtpPaths(flowId, flowPath);
      break;
    case 5:
      flowPaths = getIdOtpPaths(flowId, flowPath);
      break;
    default:
      console.info("No paths found for flow path: ", flowPath);
  }
  return flowPaths;
};

export const getDocVerOnlyPaths = (flowId, flowPath) => {
  return [
    `/Demo/${flowId}/${flowPath}/AgencyWelcome/register`,
    `/Demo/${flowId}/${flowPath}/DocVerify`,
    `/Demo/${flowId}/${flowPath}/Success`,
  ];
};

export const getIdVerOnlyPaths = (flowId, flowPath) => {
  return [
    `/Demo/${flowId}/${flowPath}/AgencyWelcome/register`,
    `/Demo/${flowId}/${flowPath}/IdVerify`,
    `/Demo/${flowId}/${flowPath}/Success`,
  ];
};

export const getOtpOnlyPaths = (flowId, flowPath) => {
  return [
    `/Demo/${flowId}/${flowPath}/AgencyWelcome/register`,
    `/Demo/${flowId}/${flowPath}/otp`,
    `/Demo/${flowId}/${flowPath}/Success`,
  ];
};

export const getDocIdOtpPaths = (flowId, flowPath) => {
  return [
    `/Demo/${flowId}/${flowPath}/AgencyWelcome/register`,
    `/Demo/${flowId}/${flowPath}/DocVerify`,
    `/Demo/${flowId}/${flowPath}/IdVerify`,
    `/Demo/${flowId}/${flowPath}/otp`,
    `/Demo/${flowId}/${flowPath}/Success`,
  ];
};

export const getIdOtpPaths = (flowId, flowPath) => {
  return [
    `/Demo/${flowId}/${flowPath}/AgencyWelcome/register`,
    `/Demo/${flowId}/${flowPath}/IdVerify`,
    `/Demo/${flowId}/${flowPath}/otp`,
    `/Demo/${flowId}/${flowPath}/Success`,
  ];
};
