import { useForm } from "react-hook-form";
import {
  StyledFormLabel,
  StyledFormGroup,
  StyledButton,
  StyledItem,
} from "./AgencySignInForm.styles";
import { Link, Typography } from "@mui/material";
import { FormTextInput } from "../FormComponents/FormComponents";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export function AgencySignInForm(props) {
  const { control, handleSubmit } = useForm({
    defaultValues: { emailAddress: "", password: "" },
  });

  const onSubmit = (data) => {
    console.log("Sign In clicked");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup>
        <Typography variant="h5">Sign in for existing users</Typography>
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledFormLabel>Email Address</StyledFormLabel>
        <FormTextInput
          variant="outlined"
          name="emailAddress"
          control={control}
          rules={{ required: true, minLength: 1 }}
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledFormLabel>Password</StyledFormLabel>
        <FormTextInput
          variant="outlined"
          name="password"
          control={control}
          rules={{ required: true, minLength: 1 }}
          type="password"
        />
      </StyledFormGroup>
      <StyledButton size="large" variant="contained" type="submit">
        Sign In
      </StyledButton>
      <StyledItem>
        <Link>Sign in with your government employee ID</Link>
      </StyledItem>
      <hr />
      <StyledItem>
        <Link>Forgot your password?</Link>
      </StyledItem>
      <StyledItem>
        <Link>
          Security Practices and Privacy Act Statement
          <OpenInNewIcon fontSize="small" />
        </Link>
      </StyledItem>
      <StyledItem>
        <Link>
          Privacy Act Statement
          <OpenInNewIcon fontSize="small" />
        </Link>
      </StyledItem>
    </form>
  );
}
