/**
 * Flow Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useState, useMemo, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
//styles
import {
  FlowPageItemAddFlow,
  flowPageStyles,
  FlowPageItem,
} from "./FlowPage.styles";
//component imports
import NavigationDrawer from "../../components/NavigationDrawer/NavigationDrawer";
import FlowsTable from "../../components/FlowsTable/FlowsTable";
import AddFlowModal from "../../components/AddFlowModal/AddFlowModal";
import PlayFlowModal from "../../components/PlayFlowModal/PlayFlowModal";
import WelcomeUserHeader from "../../components/WelcomeUserHeader/WelcomeUserHeader";
//context
import { FlowPageContext } from "../../context/FlowPageContext";

const FlowPage = () => {
  const [showAddFlowModal, setShowAddFlowModal] = useState(false);

  const updateShowAddFlowModal = useCallback(() => {
    setShowAddFlowModal(!showAddFlowModal);
  }, [showAddFlowModal]);

  const flowPageContextVals = useMemo(() => {
    return {
      showAddFlowModal,
      updateShowAddFlowModal,
    };
  }, [showAddFlowModal, updateShowAddFlowModal]);

  return (
    <FlowPageContext.Provider value={flowPageContextVals}>
      <Grid item xs={12} sm container>
        <NavigationDrawer />
        <AddFlowModal />
        <PlayFlowModal />
        <Grid item xs={10}>
          <WelcomeUserHeader />
          <FlowPageItemAddFlow>
            <Button
              sx={flowPageStyles.addFlowButton}
              onClick={updateShowAddFlowModal}
            >
              Add Flow
            </Button>
          </FlowPageItemAddFlow>
          <FlowPageItem>
            <FlowsTable />
          </FlowPageItem>
        </Grid>

        <Grid item xs={12} sm container></Grid>
      </Grid>
    </FlowPageContext.Provider>
  );
};
export default FlowPage;
