/**
 * Success page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { Box, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const successStyles = {
  confirmInfoText: {
    fontSize: "2rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  ssnPhoneText: {
    fontSize: "2rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  confirmInfoSubText: {
    fontSize: "1.5rem",
    fontFamily: "Oswald",
    color: "#8d99ae",
  },
  modalTextFieldText: {
    color: "black",
    fontFamily: "Oswald",
    fontSize: "1rem",
    paddingLeft: "2rem",
  },
  header: {
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    color: "#1e6091",
    marginTop: "1rem",
  },
};

export const SuccessPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
}));

export const SuccessButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  margin: 0,
  width: "100%",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignContent: "baseline",
  margin: "1.25rem",
  padding: theme.spacing(2),
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
  margin: "1.25rem 0",
  maxWidth: "600px",
  backgroundColor: theme.palette.layout.main,
}));
