/**
 * Doc Verification Services
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//libary imports
import { API, Auth } from "aws-amplify";

export const startPreFlight = async () => {
  const apiName = "DocVerification";
  const path = "/preFlight";
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  let response = {};
  try {
    response = await API.get(apiName, path, myInit);
  } catch (err) {
    console.log("err = ", err);
  }
  return response;
};

export const getDecision = async (token, caseId) => {
  const apiName = "DocVerification";
  const path = "/getDecision";
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      token: token,
      caseId: caseId,
    },
  };
  let response = {};
  try {
    response = await API.post(apiName, path, myInit);
  } catch (err) {
    console.log("err = ", err);
  }
  return response;
};
