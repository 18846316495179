/**
 * Back Exit Header Component Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ConsentPageBackButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  marginTop: "1rem",
  padding: theme.spacing(1),
  color: "black",
  boxShadow: "none",
}));

export const ExitItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginTop: "1rem",
  padding: theme.spacing(1),
  fontSize: "1rem",
  boxShadow: "none",
  alignContent: "right",
  justifyContent: "end",
}));

export const BackButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "7rem",
  height: "3rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#00b4d8",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
}));
