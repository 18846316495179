/**
 * Flows Table sub component where name and logo of the flow are in it
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
//icons
import Typography from "@mui/material/Typography";
//constants
import { getFlowType } from "../../utils/constants/sucessPaths";
//components
import FlowIcon from "../FlowIcon/FlowIcon";

//styles
import { flowTableNameLogoStyles } from "./FlowsTableNameLogo.styles";

const FlowsTableNameLogo = ({ flowName, flowAgency, flowSuccessPath }) => {
  const flowIconHeight = 70;
  const flowIconWidth = 70;
  const flowIconSize = "2rem";
  const successPaths = getFlowType();
  /*
  const [successIcon, setSuccessIcon] = useState(
    successPaths.find((o) => o.value === SUCCESS_PATH).icon
  );
  */
  const [successIconText, setSuccessIconText] = useState("");

  useEffect(() => {
    try {
      const flowName = successPaths.find((o) => o.value === +flowSuccessPath);
      setSuccessIconText(flowName.name);
    } catch (error) {
      console.log(error);
      setSuccessIconText("Invalid flow path");
    }
  }, [flowSuccessPath, successPaths]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={1.5}>
          <FlowIcon
            flowIconHeight={flowIconHeight}
            flowIconWidth={flowIconWidth}
            flowIconSize={flowIconSize}
          />
        </Grid>
        <Grid item xs={10}>
          <Stack>
            <Typography sx={flowTableNameLogoStyles.flowNameHeader}>
              {flowName}
            </Typography>
            <Stack direction="row" spacing={1}>
              {flowAgency.value === "NOTGOV" ? (
                <Avatar
                  variant="circle"
                  sx={{
                    background: `#4cc9f0`,
                    height: 36,
                    width: 36,
                  }}
                >
                  <LocalFireDepartmentIcon sx={{ fontSize: "1.5rem" }} />
                </Avatar>
              ) : flowAgency.value === "IRS" ? (
                <Avatar
                  sx={{
                    width: 36,
                    height: 36,
                    background: "blue",
                  }}
                >
                  <img
                    src={flowAgency.logo}
                    height="30"
                    width="30"
                    padding="0"
                    border="0"
                    alt="Create an account with ID.me"
                  />
                </Avatar>
              ) : (
                <Avatar
                  alt={flowAgency.name}
                  src={flowAgency.logo}
                  sx={{
                    width: 36,
                    height: 36,
                  }}
                />
              )}
              <Typography sx={flowTableNameLogoStyles.agencyHeader}>
                {flowAgency.name}{" "}
              </Typography>
              {/*successIcon*/}
              <Typography sx={flowTableNameLogoStyles.agencyHeader}>
                {successIconText}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FlowsTableNameLogo;
