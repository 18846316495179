/**
 * Success Page with summary of events
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//components
import AgencyHeader from "../../components/AgencyHeader/AgencyHeader";
import { Footer } from "../../components/Footer/Footer";

//styles
import {
  SuccessPageItem as Item,
  SuccessButton,
  StyledBox,
  StyledPaper,
} from "./SuccessPage.styles";

//context
import { DemoFlowContext } from "../../context/DemoFlowContext";
import { FlowContext } from "../../context/FlowContext";
import { getNextPageLink } from "../../utils/commonFunctions/commonFunctions";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resetDemo } = useContext(DemoFlowContext);
  const { flowInPlayConfig } = useContext(FlowContext);
  const nextPageLink = getNextPageLink(flowInPlayConfig, location.pathname);

  const successButton = () => {
    //updatePlaySelectedFlow();
    //updateSelectedFlow({});
    resetDemo();
    navigate(nextPageLink);
  };

  return (
    <div className="pageWrapper">
      <AgencyHeader />
      <div className="pageBody">
        <StyledBox>
          <StyledPaper elevation={4}>
            <StyledBox>
              <CheckCircleIcon
                color="success"
                sx={{ fontSize: "3.75rem", marginRight: "0.85rem" }}
              />
              <Typography variant="h3">Congratulations!</Typography>
            </StyledBox>
            <StyledBox>
              <Typography variant="h6">
                You have successfully registered for an account on login.gov.
                Click the button below to return back to the main login screen.
              </Typography>
            </StyledBox>
            <StyledBox>
              <SuccessButton onClick={successButton}>Go Login</SuccessButton>
            </StyledBox>
          </StyledPaper>
        </StyledBox>
      </div>
      <div className="pageFooter">
        <Footer />
      </div>
    </div>
  );
};
export default SuccessPage;
