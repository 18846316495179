import { sha256 } from "crypto-hash";
import uuid from "react-uuid";
import axios from "axios";

export const getSession = async (credentials) => {
  const { endpoint_url, public_key } = credentials;
  const apiUrl = `${endpoint_url}/session`;

  const headers = {
    "Content-Type": "application/json",
    "X-PublicKey": public_key,
  };

  const body = {
    SessionType: "0",
  };

  const response = await axios.post(apiUrl, body, {
    headers: headers,
  });

  return response;
};

export const getToken = async (credentials) => {
  const { password, secret, username, endpoint_url } = credentials;
  const timeStamp = new Date().toISOString();
  const passwordHash1 = await sha256(`${timeStamp}.${password}`);
  const passwordHash = await sha256(`${passwordHash1}.${secret}`);
  const userHash1 = await sha256(`${timeStamp}.${username}`);
  const userHash = await sha256(`${userHash1}.${secret}`);

  const apiUrl = `${endpoint_url}/token`;

  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    Username: username,
    Timestamp: timeStamp,
    PasswordHash: passwordHash,
    UserNameHash: userHash,
  };

  const response = await axios.post(apiUrl, body, {
    headers: headers,
  });

  return response;
};

export const getCase = async (credentials, obj, token, sessionId) => {
  const { endpoint_url, username } = credentials;
  const apiUrl = `${endpoint_url}/case`;
  const caseUuid = uuid();

  const headers = {
    "Content-Type": "application/json",
    "X-Authorization": `${username} ${token}`,
  };

  const body = {
    CaseNumber: caseUuid,
    SessionId: sessionId,
    ...obj,
  };

  const response = await axios.post(apiUrl, body, {
    headers: headers,
  });

  return response;
};

export const getDecision = async (credentials, caseId, token) => {
  const { endpoint_url, username } = credentials;
  const apiUrl = `${endpoint_url}/detaileddecision/${caseId}`;

  const headers = {
    "Content-Type": "application/json",
    "X-Authorization": `${username} ${token}`,
  };

  const response = await axios.get(apiUrl, {
    headers: headers,
  });

  return response;
};

export const getIdentityPayload = async (credentials, url, token) => {
  const { username } = credentials;
  const headers = {
    "Content-Type": "application/json",
    "X-Authorization": `${username} ${token}`,
  };

  const response = await axios.get(url, {
    headers: headers,
  });

  return response;
};

export const buildCustomerObject = (
  flowType,
  demoUserInfo = {},
  OCRData = {}
) => {
  let customerObject = {};
  let addressObject = {};

  // Get name, phone, and ssn from user input
  customerObject.FirstName = demoUserInfo.firstName;
  customerObject.LastName = demoUserInfo.lastName;
  if (demoUserInfo.ssn) customerObject.SocialSecurityNumber = demoUserInfo.ssn;
  if (demoUserInfo.phone) customerObject.PhoneNumber = demoUserInfo.phone;

  if (+flowType === 4) {
    // Get address from OCR data from document verification
    addressObject.Address1 = OCRData.AddressLine1;
    if (OCRData.AddressLine2) addressObject.Address2 = OCRData.AddressLine2;
    if (OCRData.AddressLine3) addressObject.Address3 = OCRData.AddressLine3;
    addressObject.City = OCRData.AddressCity;
    addressObject.FirstName = demoUserInfo.firstName;
    addressObject.LastName = demoUserInfo.lastName;
    addressObject.PostalCode = OCRData.AddressPostalCode;
    addressObject.State = OCRData.AddressState;
    addressObject.CountryCode = "US";
    customerObject.Addresses = [{ ...addressObject }];
  } else if ([2, 5].includes(+flowType)) {
    if (demoUserInfo.dob) customerObject.DateOfBirth = demoUserInfo.dob;

    // Get address from user input since there will be no doc ocr data
    addressObject.Address1 = demoUserInfo.addressLine1;
    if (demoUserInfo.addressLine2)
      addressObject.Address2 = demoUserInfo.addressLine2;
    addressObject.City = demoUserInfo.city;
    addressObject.FirstName = demoUserInfo.firstName;
    addressObject.LastName = demoUserInfo.lastName;
    addressObject.PostalCode = demoUserInfo.zip;
    addressObject.State = demoUserInfo.state;
    addressObject.CountryCode = "US";
    customerObject.Addresses = [{ ...addressObject }];
  }

  return customerObject;
};

export const callOTP = async (credentials, url, token) => {
  const { username } = credentials;

  const headers = {
    "Content-Type": "application/json",
    "X-Authorization": `${username} ${token}`,
  };

  let body = {
    DeliveryType: 0,
    Language: 0,
  };

  const response = await axios.post(url, body, {
    headers: headers,
  });

  return response;
};

export const verifyOTP = async (credentials, url, token, passcode) => {
  const { username } = credentials;

  const headers = {
    "Content-Type": "application/json",
    "X-Authorization": `${username} ${token}`,
  };

  let body = {
    Passcode: Number(passcode),
  };

  const response = await axios.put(url, body, {
    headers: headers,
  });

  return response;
};

/****************************************************************************
 *
 * Format functions create a new formatted object based on the initial
 * response from the TruValidate calls.  The formatted object is used
 * to display detailed information about the API call configuration
 * and resulting response as part of the demo to customers.
 */
const sessionStatusExplanation = {
  200: "Success, The action was successful",
  401: "Failure, Verify that the public key in the X-PublicKey header is correct",
  500: "Internal server error.  An error occurred generating the session id. Verify that you have included an empty JSON element, {}, as the body of the request.",
};

const tokenStatusExplanation = {
  200: "Success, The action was successful",
  400: "One of the required fields is missing or misspelled. The error description will indicate which of the required fields is missing.",
  401: "Your Password and UserNameHash do not match our records. The problem is either related to an incorrect password, secret, or due to incorrect hashing.",
};

const caseStatusExplanation = {
  200: "Success, The action was successful",
  400: "Bad Request. Please generate a valid session id",
  401: "A site with XXX in your X-Authentication header is not in the correct format",
  403: "You cannot add more cases to this session.  Ten (10) transactions have been placed for this session. Create a new session. This was implemented to prevent fraudsters and high-velocity customers from placing more than ten (10) transactions per session.",
  500: "Internal server error.  There was an error processing the request",
};

const decisionStatusExplanation = {
  200: "Success, The action was successful",
  400: "The Id in your Case Object is not a valid Trustev Case Id. Please only use the Case Id's that Trustev Returns",
  401: "A site with XXX in your X-Authentication header is not in the correct format. Check your username or token",
};

const otpStatusExplanation = {
  200: "Success, The action was successful",
  400: "Bad Request: The request is invalid",
  401: "Authentication issue.  Make sure your X-Authentication header is present and contains a valid username and token.",
  500: "Internal server error.  There was an error processing the request.",
};

export const idVerFinalStatus = {
  1: "Pass",
  2: "Flag",
  3: "Fail",
};

export const formatSessionResponse = (sessionResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-PublicKey": "Public Key Issued to you by Transunion",
  };
  const respObj = {
    endpointName: "session",
    endpointResponse: sessionResponse.data,
    status: sessionResponse.status,
    statusExplanation:
      sessionStatusExplanation[sessionResponse.status] ??
      "Unknown response code",
    headers: mockedHeader,
    body: JSON.parse(sessionResponse.config.data),
    methodType: "POST",
  };

  return respObj;
};

export const formatTokenResponse = (tokenResponse) => {
  const timestamp = new Date().toISOString();
  const mockedHeader = {};
  const mockedBody = {
    username: "your username",
    timestamp: timestamp,
    passwordhash: "password hash",
    usernamehash: "username hash",
  };

  const respObj = {
    endpointName: "token",
    endpointResponse: tokenResponse.data,
    status: tokenResponse.status,
    statusExplanation:
      tokenStatusExplanation[tokenResponse.status] ?? "Unknown response code",
    headers: mockedHeader,
    body: mockedBody,
    methodType: "POST",
  };

  return respObj;
};

export const formatCaseResponse = (caseResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-Authorization": "<site username> <token>",
  };
  let mockedBody = JSON.parse(caseResponse.config.data);
  let response = caseResponse.data;

  if (mockedBody.Customer?.SocialSecurityNumber ?? "" !== "") {
    mockedBody.Customer.SocialSecurityNumber =
      "***-**-" + mockedBody.Customer?.SocialSecurityNumber.substring(5) ??
      "****";
  }
  if (response.Customer.SocialSecurityNumber ?? "" !== "") {
    response.Customer.SocialSecurityNumber =
      "***-**-" + response.Customer?.SocialSecurityNumber.substring(7) ??
      "****";
  }

  const respObj = {
    endpointName: "case",
    endpointResponse: response,
    status: caseResponse.status,
    statusExplanation:
      caseStatusExplanation[caseResponse.status] ?? "Unknown response code",
    headers: mockedHeader,
    body: mockedBody,
    methodType: "POST",
  };

  return respObj;
};

export const formatDecisionResponse = (decisionResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-Authorization": "<site username> <token>",
  };
  let mockedBody = {};
  let response = decisionResponse.data;

  if (response.RawData?.Customer?.SocialSecurityNumber ?? "" !== "") {
    response.RawData.Customer.SocialSecurityNumber =
      "***-**-" +
        response.RawData?.Customer?.SocialSecurityNumber.substring(7) ?? "****";
  }

  const respObj = {
    endpointName: "detaileddecision",
    endpointResponse: response,
    status: decisionResponse.status,
    statusExplanation:
      decisionStatusExplanation[decisionResponse.status] ??
      "Unknown response code",
    headers: mockedHeader,
    body: mockedBody,
    methodType: "GET",
  };

  return respObj;
};

export const formatIdentityPayloadResponse = (identityResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-Authorization": "<site username> <token>",
  };
  let mockedBody = {};
  let response = identityResponse.data;

  const respObj = {
    endpointName: "identityverification",
    endpointResponse: response,
    status: identityResponse.status,
    statusExplanation:
      decisionStatusExplanation[identityResponse.status] ??
      "Unknown response code",
    headers: mockedHeader,
    body: mockedBody,
    methodType: "GET",
    endpointUrl: identityResponse.config.url ?? "",
  };

  return respObj;
};

export const formatCallOtpResponse = (otpResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-Authorization": "<site username> <token>",
  };

  const respObj = {
    endpointName: "otp",
    endpointResponse: otpResponse.data,
    status: otpResponse.status,
    statusExplanation:
      otpStatusExplanation[otpResponse.status] ?? "Unknown response code",
    headers: mockedHeader,
    body: JSON.parse(otpResponse.config.data),
    methodType: "POST",
    endpointUrl: otpResponse.config.url ?? "",
  };

  return respObj;
};

export const formatVerifyOtpResponse = (otpResponse) => {
  const mockedHeader = {
    "Content-Type": "application/json",
    "X-Authorization": "<site username> <token>",
  };

  const respObj = {
    endpointName: "otp",
    endpointResponse: otpResponse.data,
    status: otpResponse.status,
    statusExplanation:
      otpStatusExplanation[otpResponse.status] ?? "Unknown response code",
    headers: mockedHeader,
    body: JSON.parse(otpResponse.config.data),
    methodType: "PUT",
    endpointUrl: otpResponse.config.url ?? "",
  };

  return respObj;
};
