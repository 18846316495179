/**
 * Detailed Flow Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
//styles
import { DashboardItemDivider } from "../../pages/Dashboard/Dashboard.styles";
import { WelcomeItem, WelcomeSubItem } from "./WelcomeUserHeader.styles";
//component imports
import Emoji from "../../components/Emoji/Emoji";
//context
import { LoginContext } from "../../context/LoginContext";

const WelcomeUserHeader = () => {
  const { userFullName } = useContext(LoginContext);
  let welcomeHeaderStr = `Welcome Back, ${userFullName} !`;

  return (
    <Grid container>
      <Grid item xs={12}>
        <WelcomeItem>{welcomeHeaderStr}</WelcomeItem>
        <WelcomeSubItem>
          Let's crush today.
          <Emoji symbol="🤘" label="sheep" />
        </WelcomeSubItem>
        <DashboardItemDivider>
          <Divider variant="middle" />
        </DashboardItemDivider>
      </Grid>
    </Grid>
  );
};
export default WelcomeUserHeader;
