/**
 * Doc Verify Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import {
  Box,
  Button,
  Dialog,
  ListItem,
  ListItemIcon,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const docVerifyStyles = {
  scanQRText: {
    marginBottom: "10px",
    marginLeft: "120px",
    marginRight: "120px",
  },
  buttonText: {
    fontSize: "1rem",
  },
};

export const DocVerifyItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  textAlign: "center",
  color: "black",
  boxShadow: "none",
}));

export const CallOutItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textAlign: "center",
  color: theme.palette.primary.contrastText,
  maxWidth: "1100px",
  margin: "30px",
  padding: theme.spacing(1),
}));

export const ClearItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "left",
  maxWidth: "1100px",
  margin: "10px",
  padding: theme.spacing(1),
}));

export const DocVerifyHeaderItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "left",
  color: "black",
  boxShadow: "none",
}));

export const DocUploadButton = styled(Button)(({ theme }) => ({
  height: "5rem",
  width: "20rem",
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "30px",
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: "red",
  fontWeight: "600",
  fontSize: "1.1rem",
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "10px!important",
  color: "red",
  marginRight: "10px",
  fontSize: "0.85rem",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  alignContent: "center",
}));
