/**
 * Demo Flow Context
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { createContext } from "react";

/**
 * this context is in charge of updating the aws user signed in
 * initially set to not logged in, and the username is empty
 */
export const DemoFlowContext = createContext({
  demoUserFinishedUpload: false,
  updateDemoUserFinishedUpload: () => {},
  demoUserDetailedDecision: {},
  updateDemoUserDetailedDecision: () => {},
  allCallsMade: {},
  updateAllCallsMade: () => {},
  demoUserInfo: {},
  updateDemoUserInfo: () => {},
  idVerCallsMade: {},
  updateIdVerCallsMade: () => {},
  otpCallsMade: {},
  updateOtpCallsMade: () => {},
  resetCalls: () => {},
  resetDemo: () => {},
});
