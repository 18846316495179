import { Box, Dialog, ListItem, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "30px",
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  alignContent: "center",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: "red",
  fontWeight: "600",
  fontSize: "1.1rem",
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "10px!important",
  color: "red",
  marginRight: "10px",
  fontSize: "0.85rem",
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
  },
}));
