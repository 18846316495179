/**
 * Dashboard Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const dashboardPageStyles = {
  mainBox: { flexGrow: 1, background: "white", height: "100vh" },
  flowsHeader: {
    fontSize: "2rem",
    color: "#013a63",
    fontFamily: "Oswald",
    fontVariant: "small-caps",
    marginLeft: "2rem",
  },
};

export const DashboardPageItemWelcome = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginTop: "3.75rem",
  height: "2.5rem",
  color: "#013a63",
  paddingLeft: "2rem",
  paddingRight: "10rem",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  boxShadow: "none",
}));

export const DashboardPageItemWelcomeSub = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  height: "1.75rem",
  color: "#979dac",
  paddingLeft: "2rem",
  paddingRight: "10rem",
  fontSize: "1.1rem",
  fontFamily: "Oswald",
  boxShadow: "none",
}));

export const DashboardPageItemAvatar = styled(Paper)(({ theme }) => ({
  marginTop: "3.75rem",
  backgroundColor: "transparent",
  height: "2.5rem",
  boxShadow: "none",
}));

export const DashboardPageItemCalendar = styled(Paper)(({ theme }) => ({
  marginTop: "5rem",
  backgroundColor: "transparent",
  height: "25rem",
  width: "25rem",

  boxShadow: "none",
}));

export const DashboardPageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  color: "black",
  //   boxShadow: "none",
}));

export const DashboardItemDivider = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  paddingLeft: "1rem",
  height: "0.5rem",
  paddingTop: "1rem",
}));

export const DashboardItemCard = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  paddingLeft: "1rem",
  // height: "0.5rem",
  paddingTop: "1rem",
}));

export const DashboardItemMostRecentFlows = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  paddingLeft: "1rem",
  // height: "0.5rem",
  marginTop: "8rem",
}));
export const DashboardWelcomeContentSub = styled(Paper)(({ theme }) => ({
  backgroundColor: "pink",
  padding: theme.spacing(2),
  color: "#979dac",
  //   boxShadow: "none",
  paddingLeft: "10rem",
  paddingRight: "10rem",
  fontSize: "1.1rem",
  fontFamily: "Oswald",
}));

export const dashboardCardStyles = {
  cardStyleBlue: {
    width: 220,
    height: 270,
    background: "linear-gradient(to left top, #b6dde5 25%, #e0dee8)",
    backgroundRepeat: "no-repeat",
    color: "white",
    marginRight: "1.5rem",
    fontFamily: "Oswald",
  },
  cardStyleDarkBlue: {
    width: 220,
    height: 270,
    background: "linear-gradient(to left top,  #2c3e50 25%,#2980b9)",
    backgroundRepeat: "no-repeat",
    color: "white",
    fontFamily: "Oswald",
    marginRight: "1.5rem",
  },
};
