/**
 * Doc Verify Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { Box, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const otpStyles = {
  scanQRText: {
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  otpSubText: {
    fontSize: "1.5rem",
    fontFamily: "Oswald",
    color: "#8d99ae",
  },
  didntReceiveText: {
    fontSize: "1rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  questionText: {
    fontSize: "1rem",
    fontFamily: "Oswald",
    color: "#f8961e",
    textDecoration: "underline",
    cursor: "pointer",
  },
};
export const OtpItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  textAlign: "center",
  boxShadow: "none",
  alignContent: "center",
  alignItems: "center",
}));

export const VerifyButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "17rem",
  height: "7rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#90e0ef",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));

export const OtpInputItem = styled(Box)(({ theme }) => ({
  marginLeft: "2rem",
  marginRight: "2rem",
}));
