/**
 * Add flows modal  component styles
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const InvalidLoginPopupStyles = {
  modalBox: {
    marginTop: "30vh",
    marginLeft: "34vw",
    width: "30vw",
    bgcolor: "white",
    p: 2,
    borderRadius: "1rem",
    "@media (min-width:1919px)": {
      // eslint-disable-line no-useless-computed-key
      width: "30rem",
    },
  },
  modalHeader: {
    fontFamily: "Oswald",
    fontSize: "2rem",
    color: "#013a63",
    marginLeft: "1rem",
  },
  exitButton: {
    maxWidth: "2.2rem",
    maxHeight: "2.2rem",
    minWidth: "2.2rem",
    minHeight: "2.2rem",
    background: "#adb5bd",
    "&:hover": {
      background: "#6c757d",
      borderColor: "#6c757d",
      boxShadow: "none",
    },
  },
  modalTextFieldText: {
    color: "black",
    fontFamily: "Oswald",
    fontSize: "1rem",
    paddingLeft: "2rem",
  },
  beforeInputText: {
    fontFamily: "Oswald",
    fontSize: "1rem",
    marginLeft: "2rem",
    color: "#6c757d",
  },
  selectStyle: {
    width: "27rem",
    padding: "10px 12px",
    marginLeft: "1rem",
  },
  menuItemStyle: {
    fontFamily: "Oswald",
  },
  customStyleSuccess: {
    color: "green",
    width: 26,
    height: 26,
  },
  customStyleFail: {
    color: "#d90429",
    width: 26,
    height: 26,
  },
  addFlowButton: {
    background: "#219ebc",
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    width: "20rem",
    color: "white",
    "&:hover": {
      background: "#023047",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
};

export const AddFlowModalTextField = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "0.5px solid #ced4da",
    width: "25rem",
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Oswald", "sans-serif"].join(","),
    "&:focus": {
      boxShadow: `black`,
      borderColor: "#ced4da",
    },
  },
}));

export const AddFlowModalTextFieldMulitline = styled(InputBase)(
  ({ theme }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "transparent",
      border: "0.5px solid #ced4da",
      width: "25rem",
      padding: "10px 12px",
      height: "5rem !important",
      // Use the system font instead of the default Roboto font.
      fontFamily: ["Oswald", "sans-serif"].join(","),
      "&:focus": {
        boxShadow: `black`,
        borderColor: "#ced4da",
      },
    },
  })
);
