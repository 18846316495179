/**
 * Flows Table Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
//components
import FlowsTableNameLogo from "../FlowsTableNameLogo/FlowsTableNameLogo";
//styles
import { flowTableStyles } from "./FlowsTable.styles";
//context
import { FlowContext } from "../../context/FlowContext";
const FlowsTable = () => {
  const [currentFlows, setCurrentFlows] = useState([]);
  const { flows, updateSelectedFlow, updateShowPlayFlowModal } =
    useContext(FlowContext);
  const handleRowClick = (row) => {
    updateSelectedFlow(row);
    updateShowPlayFlowModal();
  };

  useEffect(() => {
    setCurrentFlows(flows);
  }, [flows]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={flowTableStyles.tableStyles} aria-label="simple table">
        <TableHead />
        <TableBody>
          {currentFlows.length > 0 ? (
            currentFlows.map((row, index) => (
              <TableRow key={index} hover onClick={() => handleRowClick(row)}>
                <TableCell>
                  <FlowsTableNameLogo
                    flowName={row.flowName}
                    flowAgency={row.flowAgency}
                    flowSuccessPath={row.flowSuccessPath}
                  />
                </TableCell>
                <TableCell align="right">
                  <Typography sx={flowTableStyles.flowLastDemoDate}>
                    {row.flowDemoDate}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlowsTable;
