/**
 * Parameter Responses Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack, Divider } from "@mui/material";

//styles
import { paramStyles } from "./ParameterResponses.styles";

const ParameterResponses = ({ typeOfHeader }) => {
  return (
    <Stack direction="column">
      <Box
        sx={{
          backgroundColor: "#f2f9ff",
          padding: "0.5rem",
          marginBottom: "1rem",
          borderRadius: "0.5rem",
          marginTop: "1rem",
        }}
      >
        <Typography sx={paramStyles.parameters}>
          {typeOfHeader === "params" ? "Parameters" : "Responses"}
        </Typography>
      </Box>
      <Stack direction="row" spacing={22}>
        <Typography sx={paramStyles.nameDescription}>
          {" "}
          {typeOfHeader === "params" ? "Name" : "Code"}
        </Typography>
        <Typography sx={paramStyles.nameDescription}>
          {typeOfHeader === "params" ? "Example" : "Response"}
        </Typography>
      </Stack>
      <Divider sx={{ marginBottom: "1rem", marginTop: "0.25rem" }} />
    </Stack>
  );
};

export default ParameterResponses;
