/**
 * Consent Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../services/Authentication";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import MenuIcon from "@mui/icons-material/Menu";

//global context
import { FlowContext } from "../../context/FlowContext";
import { DemoFlowContext } from "../../context/DemoFlowContext";
import { LoginContext } from "../../context/LoginContext";

//components
import { AgencyStyledToolbar } from "./AgencyHeader.styles";
import { AllApiCalls } from "../AllApiCalls/AllApiCalls";

const AgencyHeader = () => {
  const navigate = useNavigate();
  const { selectedFlow, flowInPlayConfig } = useContext(FlowContext);
  const { resetDemo } = useContext(DemoFlowContext);
  const { resetLogin } = useContext(LoginContext);
  const [showApiCalls, setShowApiCalls] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleApiClick = () => {
    setShowApiCalls(!showApiCalls);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    resetDemo();
    navigate(path);
  };

  const handleSignOut = async () => {
    resetLogin();
    navigate("/", { replace: true });
    signOut();
  };

  return (
    <AppBar position="fixed" className="pageHeader">
      <AgencyStyledToolbar agency={selectedFlow.flowAgency.value}>
        <Button
          variant="text"
          onClick={handleMenuClick}
          size="large"
          sx={{ alignSelf: "flex-end", fontSize: "48px!important" }}
        >
          <MenuIcon />
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        >
          <MenuItem onClick={() => handleNavigation(flowInPlayConfig[0])}>
            Home
          </MenuItem>
          <MenuItem onClick={() => handleNavigation("/Dashboard")}>
            Dashboard
          </MenuItem>
          <MenuItem onClick={handleSignOut}>Logout</MenuItem>
        </Menu>
        {selectedFlow.flowAgency.value === "NOTGOV" ? (
          <Avatar
            variant="circle"
            sx={{
              marginTop: "0.5rem",
              background: `#4cc9f0`,
              height: "5rem",
              width: "5rem",
              marginLeft: "14rem",
            }}
          >
            <LocalFireDepartmentIcon sx={{ fontSize: "4rem" }} />
          </Avatar>
        ) : (
          <Box
            component="img"
            sx={selectedFlow.flowAgency.styles.logoStyle}
            alt="IRS"
            src={
              selectedFlow.flowAgency.value === "DOS"
                ? "https://www.state.gov/wp-content/themes/state/images/logos/Logo_White_XL.png"
                : selectedFlow.flowAgency.value === "VA"
                ? "https://s3-us-gov-west-1.amazonaws.com/content.www.va.gov/img/header-logo.png"
                : selectedFlow.flowAgency.logo
            }
          />
        )}
        {selectedFlow.flowAgency.value === ("DOS" || "VA") ? (
          <></>
        ) : (
          <Typography
            variant="h6"
            component="div"
            sx={selectedFlow.flowAgency.styles.agencyHeaderStyle}
          >
            {selectedFlow.flowAgency.name}
          </Typography>
        )}
        <Tooltip title="View API call details">
          <Button
            variant="contained"
            onClick={handleApiClick}
            sx={{ alignSelf: "center" }}
          >
            <Typography>API</Typography>
          </Button>
        </Tooltip>
        <Drawer
          anchor="left"
          hideBackdrop={false}
          open={showApiCalls}
          onClose={handleApiClick}
        >
          <AllApiCalls />
        </Drawer>
      </AgencyStyledToolbar>
    </AppBar>
  );
};
export default AgencyHeader;
