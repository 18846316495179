import { styled } from "@mui/material/styles";
import { Box, Button, FormGroup, FormLabel } from "@mui/material";

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  margin: "1.25rem 0",
  padding: theme.spacing(2),
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  marginBottom: "20px",
}));

export const StyledItem = styled(Box)(({ theme }) => ({
  textAlign: "left",
  margin: "1rem 0",
}));
