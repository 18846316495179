/**
 * Add Flow Modal Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
//icons
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
//components
import FlowDetailsTable from "../FlowDetailsTable/FlowDetailsTable";
//styles
import { AddFlowModalStyles } from "../AddFlowModal/AddFlowModal.styles";
import {
  DetailedFlowPageItemAddFlow,
  playFlowModalStyles,
} from "./PlayFlowModal.styles";
///context
import { FlowContext } from "../../context/FlowContext";
//services
import { deleteFlowById, getFlows } from "../../services/Flows/FlowServices";
//constants
import {
  SUCCESS_PATH,
  FAIL_OTP_PATH,
  FAIL_ID_SELFIE_PATH,
} from "../../utils/constants/sucessPaths";
import { getFlowPaths, successConfig } from "../../configs/successConfig";
import { failOtpConfig } from "../../configs/failOtpConfig";
import { failIdConfig } from "../../configs/failIdConfig";

const PlayFlowModal = () => {
  const navigate = useNavigate();
  const {
    updateFlowInPlayConfig,
    updatePlaySelectedFlow,
    selectedFlow,
    updateFlows,
    updateShowPlayFlowModal,
    showPlayFlowModal,
  } = useContext(FlowContext);

  const handleModalClose = async () => {
    updateShowPlayFlowModal();
  };

  const deleteFlow = async () => {
    await deleteFlowById(selectedFlow.id);
    updateShowPlayFlowModal();
    let flowsInDB = await getFlows();
    updateFlows(flowsInDB);
  };

  const playButtonClicked = async () => {
    const flowId = selectedFlow.id;
    const flowType = selectedFlow.flowSuccessPath;
    const flowPaths = getFlowPaths(flowId, flowType);
    updateFlowInPlayConfig(flowPaths);
    /*
    switch (selectedFlow.flowSuccessPath) {
      case SUCCESS_PATH:
        let successConfigCustomized = successConfig(flowId);
        await updateFlowInPlayConfig(successConfigCustomized);
        break;
      case FAIL_OTP_PATH:
        let failOtpConfigCustomized = failOtpConfig(flowId);
        await updateFlowInPlayConfig(failOtpConfigCustomized);
        break;
      case FAIL_ID_SELFIE_PATH:
        let failIdConfigCustomized = failIdConfig(flowId);
        await updateFlowInPlayConfig(failIdConfigCustomized);
        break;
      default:
        console.log("unknown path");
    }
    */
    await updatePlaySelectedFlow();
    navigate(`${flowPaths[0]}`);
    updateShowPlayFlowModal();
  };

  return (
    <Modal open={showPlayFlowModal} onClose={handleModalClose}>
      <Box sx={AddFlowModalStyles.modalBox}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" xs={12} justifyContent="end">
              <Button
                variant="contained"
                sx={AddFlowModalStyles.exitButton}
                onClick={updateShowPlayFlowModal}
              >
                <CloseIcon />
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row">
              <Typography sx={AddFlowModalStyles.modalHeader}>
                Start {selectedFlow.flowName}
              </Typography>
              <DetailedFlowPageItemAddFlow>
                <PlayCircleIcon
                  sx={playFlowModalStyles.playButton}
                  onClick={playButtonClicked}
                />
              </DetailedFlowPageItemAddFlow>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Stack direction={"column"}>
                <Grid item>
                  <Typography sx={AddFlowModalStyles.beforeInputText}>
                    flow details
                  </Typography>
                </Grid>
                <Grid item sx={{ marginLeft: "2rem" }}>
                  <FlowDetailsTable />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item sx={{ marginTop: "2rem" }}>
                <Button
                  sx={playFlowModalStyles.deleteFlowButton}
                  onClick={deleteFlow}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PlayFlowModal;
