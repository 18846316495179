/**
 * Api Calls Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";

//icons
import PersonIcon from "@mui/icons-material/Person";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";

//components
//styles
import { apiCallStyles } from "./ApiCalls.styles";
import SingleApiCall from "../SingleApiCall/SingleApiCall";

const LinearIndeterminate = () => {
  return (
    <Box sx={{ width: "8rem", paddingTop: "1.25rem", color: "#ffffb7" }}>
      <LinearProgress color="inherit" />
    </Box>
  );
};
const ApiCalls = ({ calls, headerText }) => {
  return (
    <Grid item xs={12} container>
      <Stack direction="column" sx={{ width: "100%", alignItems: "center" }}>
        <Typography sx={apiCallStyles.callHeader}>{headerText}</Typography>
        <Box
          sx={{
            backgroundColor: "#f2f9ff",
            width: "80rem",
            borderRadius: "1rem",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <PersonIcon sx={{ fontSize: "3rem", color: "#1e6091" }} />
            <LinearIndeterminate />
            <FilterDramaIcon sx={{ fontSize: "3rem", color: "#1e6091" }} />
          </Stack>
          <Stack direction="column">
            {Object.entries(calls).map(([key, value]) => {
              return <SingleApiCall call={value} key={key} />;
            })}
          </Stack>
        </Box>
      </Stack>
    </Grid>
  );
};

export default ApiCalls;
