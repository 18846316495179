import { SSMClient, GetParametersByPathCommand } from "@aws-sdk/client-ssm";

/**
 * Common application functions
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
/**
 * checks if object is empty
 * @param {*} objectToCheck
 */
export const isEmpty = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 * create initial flow object to save to db
 * @param {*} flowName
 * @param {*} flowSuccessPath
 * @param {*} flowAgency
 * @param {*} flowNotes
 * @returns
 */
export const createInitialFlowObject = (
  flowName,
  flowSuccessPath,
  flowAgency,
  flowNotes
) => {
  let flowID = Date.now();
  return {
    flowID,
    flowName,
    flowSuccessPath,
    flowAgency,
    flowNotes,
  };
};

export const getShortcodeFromUrl = (url) => {
  let shortcode = "";
  const boundaryIndex = url.lastIndexOf("/");
  if (boundaryIndex >= 0) {
    shortcode = url.substring(boundaryIndex + 1);
  }
  return shortcode;
};

export const getSSMParams = async (
  path,
  credentials,
  region = "us-east-1",
  withDecryption = true
) => {
  const config = { region: region, credentials: credentials };
  const input = {
    Path: path,
    WithDecryption: withDecryption,
  };
  let apiCreds = {};

  try {
    const client = new SSMClient(config);
    const command = new GetParametersByPathCommand(input);
    const response = await client.send(command);

    // Get the last part of the path of the parameter name and set that as the parameter
    // name in the js object
    response.Parameters.forEach((p) => {
      const parts = p.Name.split("/");
      apiCreds[parts[parts.length - 1]] = p.Value;
    });
  } catch (error) {
    console.error(
      "Error parsing out parameters from AWS System Manager Parameter Store",
      error
    );
  }

  return apiCreds;
};

export const getNextPageLink = (paths, current) => {
  let nextPageLink = "";

  try {
    const index = paths.findIndex((p) => p === current);
    if (index > -1) nextPageLink = paths[index + 1] ?? "/Dashboard";
  } catch (error) {
    console.log(error);
  }

  return nextPageLink;
};
