import CircleIcon from "@mui/icons-material/Circle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  StyledBox,
  StyledDialog,
  StyledListItem,
  StyledListItemIcon,
} from "./ResponseDialog.styles";
import { Box, Button, List, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";

import { DemoFlowContext } from "../../context/DemoFlowContext";
import { FlowContext } from "../../context/FlowContext";

export function ResponseDialog(props) {
  const navigate = useNavigate();
  const { resetDemo } = useContext(DemoFlowContext);
  const { flowInPlayConfig } = useContext(FlowContext);
  const {
    redirectLink,
    status,
    setStatus,
    verifyType,
    open,
    setOpen,
    failureMessages = [],
  } = props;

  const handleClose = () => {
    setOpen(!open);
  };
  const headerText =
    status === "success"
      ? "Success"
      : status === "flag"
      ? "More Information Needed"
      : "Issue Encountered";

  let headerDesc = "";
  if (status === "success" && verifyType === "document")
    headerDesc =
      "Your documents were successfully verified. Click the Next button below to continue with registration.";
  else if (status === "success" && verifyType === "identity")
    headerDesc =
      "Your identity was successfully verified. Click the Next button below to continue with registration.";
  else if (status === "success" && verifyType === "otp")
    headerDesc =
      "Your one time passcode (OTP) was successfully verified. Click the Next button below to continue with registration.";
  else if (status === "flag" && verifyType === "identity")
    headerDesc =
      "Unfortunately the information you provided is not enough to confirm your account.  One of our representatives will be in touch to get additional information to continue with the process.";
  else if (status === "failure" && verifyType === "document")
    headerDesc =
      "There was an issue verifying your documents. We're not able to continue with registration due to the following issues:";
  else if (status === "failure" && verifyType === "identity")
    headerDesc =
      "There was an issue verifying your identity. We're not able to continue with registration process at this time.";
  else if (status === "failure" && verifyType === "otp")
    headerDesc =
      "There was an issue verifying your one time passcode (OTP).  Please close this window and try a new code.";

  let buttonText = "";
  if (status === "success") buttonText = "Next";
  else if (status === "failure" && verifyType === "otp") buttonText = "Close";
  else if (["flag", "failure"].includes(status)) buttonText = "Return Home";

  const buttonClicked = (event) => {
    if (buttonText === "Close") {
      setStatus("");
      handleClose();
    } else if (status === "success") navigate(redirectLink);
    else {
      resetDemo();
      navigate(flowInPlayConfig[0]);
    }
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <StyledBox>
        <StyledBox>
          {status === "success" ? (
            <ThumbUpIcon fontSize="large" sx={{ marginRight: "10px" }} />
          ) : (
            <ReportProblemIcon fontSize="large" sx={{ marginRight: "10px" }} />
          )}
        </StyledBox>
        <Typography variant="h4">{headerText}</Typography>
      </StyledBox>
      <Box sx={{ margin: "20px 0" }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          {headerDesc}
        </Typography>
        {status === "failure" && failureMessages.length > 0 && (
          <List>
            {failureMessages.map((r) => {
              return (
                <StyledListItem key={r}>
                  <StyledListItemIcon>
                    <CircleIcon fontSize="small" />
                  </StyledListItemIcon>
                  {r}
                </StyledListItem>
              );
            })}
          </List>
        )}
      </Box>
      <Button color="primary" variant="contained" onClick={buttonClicked}>
        {buttonText}
      </Button>
    </StyledDialog>
  );
}
