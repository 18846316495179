/**
 * One time passcode Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  getSession,
  getToken,
  getCase,
  getDecision,
  buildCustomerObject,
  callOTP,
  verifyOTP,
  formatCaseResponse,
  formatDecisionResponse,
  formatSessionResponse,
  formatTokenResponse,
  formatCallOtpResponse,
  formatVerifyOtpResponse,
} from "../../services/IDVerification/IDVerification";

//styles
import { OtpItem as Item, otpStyles, OtpInputItem } from "./OtpPage.styles";

//component imports
import AgencyHeader from "../../components/AgencyHeader/AgencyHeader";
import { ResponseDialog } from "../../components/ResponseDialog/ResponseDialog";
import {
  getNextPageLink,
  isEmpty,
} from "../../utils/commonFunctions/commonFunctions";

//context
import { DemoFlowContext } from "../../context/DemoFlowContext";
import { LoginContext } from "../../context/LoginContext";
import { FlowContext } from "../../context/FlowContext";
import { Footer } from "../../components/Footer/Footer";

const OtpPage = () => {
  const location = useLocation();
  const { flowInPlayConfig, selectedFlow } = useContext(FlowContext);
  const { flowSuccessPath } = selectedFlow;
  const nextPageLink = getNextPageLink(flowInPlayConfig, location.pathname);
  const { demoUserInfo, allCallsMade, otpCallsMade, updateOtpCallsMade } =
    useContext(DemoFlowContext);
  const { apiDetails } = useContext(LoginContext);
  const { idmv_otp_details } = apiDetails;

  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp, setOtp] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState("");
  const [caseId, setCaseId] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [decision, setDecision] = useState({});
  const [authUrl, setAuthUrl] = useState("");
  const [callsStarted, setCallsStarted] = useState(false);
  const [callsEnded, setCallsEnded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [otpFinalStatus, setOtpFinalStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  let subText = `Enter the one time passcode sent via SMS to ${demoUserInfo.phone}`;

  useEffect(() => {
    setOtp(otp1.trim() + otp2.trim() + otp3.trim());
  }, [otp1, otp2, otp3, setOtp]);

  const resendClicked = () => {
    console.log("resendClicked");
    const { OCRData } =
      allCallsMade.call5?.endpointResponse?.Authentication
        ?.DocumentAuthentication ?? {};
    const customerObject = buildCustomerObject(
      flowSuccessPath,
      demoUserInfo,
      OCRData
    );
    getOtp(customerObject);
    setShowSnackbar(true);
  };

  const verifyClicked = async (event) => {
    console.log(otp);
    setCallsStarted(true);
    //submit OTP code
    const otpVerifyResponse = await verifyOTP(
      idmv_otp_details,
      authUrl,
      token,
      otp
    );
    updateOtpCallsMade({
      call6: { ...formatVerifyOtpResponse(otpVerifyResponse) },
    });
    const decision = await getDecision(idmv_otp_details, caseId, token);
    updateOtpCallsMade({ call7: { ...formatDecisionResponse(decision) } });
    setCallsEnded(true);
    const otpResultCode = decision.data?.Authentication?.OTP?.Status ?? -1;
    if (otpResultCode !== 1) {
      setOtpFinalStatus("failure");
      setOtp("");
    } else {
      setOtpFinalStatus("success");
    }
    setDialogOpen(true);
  };

  const getOtp = async (customerObject) => {
    let sessionId,
      token,
      caseNumber,
      caseId = "";
    let decisionObj = {};

    const sessionResponse = await getSession(idmv_otp_details);
    updateOtpCallsMade({
      call1: { ...formatSessionResponse(sessionResponse) },
    });
    if (sessionResponse.status === 200) {
      sessionId = sessionResponse.data.SessionId;
      setSessionId(sessionId);
    }
    const tokenResponse = await getToken(idmv_otp_details);
    updateOtpCallsMade({ call2: { ...formatTokenResponse(tokenResponse) } });
    if (tokenResponse.status === 200) {
      token = tokenResponse.data.APIToken;
      setToken(token);
    }
    if (sessionId && token) {
      const caseResponse = await getCase(
        idmv_otp_details,
        { Customer: customerObject },
        token,
        sessionId
      );
      caseNumber = caseResponse.data.CaseNumber;
      setCaseNumber(caseNumber);
      caseId = caseResponse.data.Id;
      setCaseId(caseId);
      updateOtpCallsMade({ call3: { ...formatCaseResponse(caseResponse) } });
    }
    if (token && caseNumber && caseId) {
      decisionObj = await getDecision(idmv_otp_details, caseId, token);
      setDecision(decisionObj);
      updateOtpCallsMade({
        call4: { ...formatDecisionResponse(decisionObj) },
      });
    }
    if (decisionObj.data.Authentication.OTP.Status === 0) {
      const { AuthURL } = decisionObj.data.Authentication.OTP;
      setAuthUrl(AuthURL);
      const otpResponse = await callOTP(idmv_otp_details, AuthURL, token);
      updateOtpCallsMade({
        call5: { ...formatCallOtpResponse(otpResponse) },
      });
    }
  };

  useEffect(() => {
    if (demoUserInfo.phone && isEmpty(otpCallsMade)) {
      const { OCRData } =
        allCallsMade.call5?.endpointResponse?.Authentication
          ?.DocumentAuthentication ?? {};
      const customerObject = buildCustomerObject(
        flowSuccessPath,
        demoUserInfo,
        OCRData
      );
      getOtp(customerObject);
    }
  }, []);

  useEffect(() => {
    if (callsStarted && !callsEnded) setLoading(true);
    else setLoading(false);
  }, [callsStarted, callsEnded, setLoading]);

  return (
    <div className="pageWrapper">
      <AgencyHeader />
      <div className="pageBody">
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Item>
              <Typography sx={otpStyles.scanQRText}>
                OTP Verification
              </Typography>
            </Item>
            <Item>
              <Typography sx={otpStyles.otpSubText}>{subText}</Typography>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Grid container justifyContent="center">
                <Box
                  sx={{
                    backgroundColor: "#f2f9ff",
                    borderRadius: "20px",
                    padding: "40px",
                  }}
                >
                  <Grid item xs={12}>
                    <Item sx={{ display: "flex", flexWrap: "wrap" }}>
                      <OtpInputItem>
                        <OtpInput
                          value={otp1}
                          onChange={(newValue) => setOtp1(newValue)}
                          numInputs={3}
                          separator={
                            <span style={{ fontSize: "3rem" }}>-</span>
                          }
                          inputStyle={{
                            border: "2px solid black",
                            borderRadius: "8px",
                            width: "5rem",
                            height: "5rem",
                            fontSize: "2rem",
                            margin: "0.5rem",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          shouldAutoFocus
                        />
                      </OtpInputItem>
                      <OtpInputItem>
                        <OtpInput
                          value={otp2}
                          onChange={(newValue) => setOtp2(newValue)}
                          numInputs={3}
                          separator={
                            <span style={{ fontSize: "3rem" }}>-</span>
                          }
                          inputStyle={{
                            border: "2px solid black",
                            borderRadius: "8px",
                            width: "5rem",
                            height: "5rem",
                            fontSize: "2rem",
                            margin: "0.5rem",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          shouldAutoFocus
                        />
                      </OtpInputItem>
                      <OtpInputItem>
                        <OtpInput
                          value={otp3}
                          onChange={(newValue) => setOtp3(newValue)}
                          numInputs={3}
                          separator={
                            <span style={{ fontSize: "3rem" }}>-</span>
                          }
                          inputStyle={{
                            border: "2px solid black",
                            borderRadius: "8px",
                            width: "5rem",
                            height: "5rem",
                            fontSize: "2rem",
                            margin: "0.5rem",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          shouldAutoFocus
                        />
                      </OtpInputItem>
                    </Item>
                    <Grid item xs={12} sx={{ marginTop: "2rem" }}>
                      <Item>
                        <Typography sx={otpStyles.didntReceiveText}>
                          Didnt Recieve OTP code?
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: "1rem" }}>
                      <Item>
                        <Typography
                          sx={otpStyles.questionText}
                          onClick={resendClicked}
                        >
                          Resend Code
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Item>
            <Item>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={verifyClicked}
                loading={loading}
                loadingPosition="center"
                sx={{ margin: "20px", minWidth: "160px", height: "50px" }}
              >
                Confirm
              </LoadingButton>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          ></Grid>
        </Grid>
      </div>
      <div className="pageFooter">
        <Footer />
      </div>
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(!showSnackbar)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={7000}
        sx={{
          "& .MuiAlert-message": {
            fontSize: "1.25rem",
          },
        }}
      >
        <Alert severity="success">New one time passcode sent!</Alert>
      </Snackbar>
      <ResponseDialog
        verifyType="otp"
        redirectLink={nextPageLink}
        status={otpFinalStatus}
        setStatus={setOtpFinalStatus}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </div>
  );
};
export default OtpPage;
