import { createTheme } from "@mui/material/styles";

const TU_BLUE = "#00A6CA";

// start with standard theme overrides
let appTheme = createTheme({
  palette: {
    primary: {
      main: "#004364",
      contrastText: "white",
    },
    secondary: {
      main: TU_BLUE,
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: ["Oswald", "roboto", "sans-serif"].join(","),
  },
  fontFamily: ["Oswald", "roboto", "sans-serif"].join(","),
});

// then extend with custom colors using the augment color utility
appTheme = createTheme(appTheme, {
  palette: {
    layout: appTheme.palette.augmentColor({
      color: {
        main: "#f2f9ff",
      },
      name: "layout",
    }),
  },
});

export default appTheme;
