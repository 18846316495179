/**
 * Flows Services
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//libary imports
import { API, graphqlOperation } from "aws-amplify";
//configs
import { availableAgencies } from "../../utils/constants/agencies";
//constants

//mutations
import { createFlow, deleteFlow } from "./mutations";
import { listFlows } from "./queries";

export const getFlows = async () => {
  try {
    const flows = await API.graphql(graphqlOperation(listFlows));
    let allFlows = flows.data.listFlows.items;
    let flowsToReturn = allFlows.map((currFlow, index) => {
      switch (currFlow.flowAgency) {
        case "DOL":
          currFlow.flowAgency = availableAgencies.DOL;
          break;
        case "IRS":
          currFlow.flowAgency = availableAgencies.IRS;
          break;
        case "DOS":
          currFlow.flowAgency = availableAgencies.DOS;
          break;
        case "VA":
          currFlow.flowAgency = availableAgencies.VA;
          break;
        case "USDT":
          currFlow.flowAgency = availableAgencies.USDT;
          break;
        case "GSA": 
          currFlow.flowAgency = availableAgencies.GSA;
          break;
        case "NOTGOV":
          currFlow.flowAgency = availableAgencies.NOTGOV;
          break;
        default:
      }
      return currFlow;
    });

    return flowsToReturn;
  } catch (err) {
    console.error(err);
  }
};

export const getNumFlowsDemoed = async () => {
  try {
    let flowsDemoed = await API.graphql({
      query: listFlows,
      variables: { filter: { flowDemoed: { eq: true } } },
    });
    return flowsDemoed.data.listFlows.items.length;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const getNumFlowsUndemoed = async () => {
  try {
    let flowsNotDemoed = await API.graphql({
      query: listFlows,
      variables: { filter: { flowDemoed: { eq: false } } },
    });
    return flowsNotDemoed.data.listFlows.items.length;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const deleteFlowById = async (flowId) => {
  try {
    await API.graphql({
      query: deleteFlow,
      variables: { input: { id: flowId } },
    });
  } catch (err) {
    console.error(err);
  }
};

export const addFlowToDB = async (newFlow) => {
  try {
    let newFlowToAdd = {
      id: newFlow.flowID,
      flowName: newFlow.flowName,
      flowAgency: newFlow.flowAgency,
      flowSuccessPath: newFlow.flowSuccessPath,
      flowNotes: newFlow.flowNotes,
      flowLastPlayedDate: new Date().toISOString(),
      flowDemoed: false,
    };
    await API.graphql({
      query: createFlow,
      variables: { input: newFlowToAdd },
    });
  } catch (error) {
    console.log("error = ", error);
  }
};
