/**
 * Confirm Info Page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const confirmInfoStyles = {
  confirmInfoText: {
    fontSize: "2rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  confirmInfoSubText: {
    fontSize: "1.5rem",
    fontFamily: "Oswald",
    color: "#8d99ae",
  },
};

export const ConfirmInfoItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
}));

export const ConfirmButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "17rem",
  height: "7rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#90e0ef",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));
