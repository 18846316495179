import { Box, TextField } from "@mui/material";
import { Controller, useController } from "react-hook-form";

export function FormTextInput(props) {
  const { field, fieldState } = useController(props);
  const { type } = props || "input";

  return (
    <Box>
      <Controller
        {...props}
        render={() => (
          <TextField {...field} type={type} sx={{ width: "100%" }} />
        )}
      />
      <Box sx={{ display: "none" }}>
        <p>{fieldState.isTouched && "Touched"}</p>
        <p>{fieldState.isDirty && "Dirty"}</p>
        <p>{fieldState.invalid ? "invalid" : "valid"}</p>
      </Box>
    </Box>
  );
}
