export const docs = {
  openapi: "3.0.0",
  info: {
    title: "Document Verification API",
    description: "Api Calls to Document Verification API",
    version: "2.0.0",
    contact: {
      email: "misae.evans@transunion.com",
    },
  },
  servers: [
    {
      url: "https://app.trustev.com/api/v2.0",
      description: "Main Production Server",
    },
  ],
  tags: [
    {
      name: "preflight",
      description: "Calls you need to make before you scan an ID",
    },
  ],
  paths: {
    "/session": {
      post: {
        security: [{ public_key: [] }],
        tags: ["preflight"],
        summary: "Returns current session",
        description: "",
        requestBody: {
          description: "Establish a new session with Document Verification",
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Session",
              },
            },
          },
        },
        responses: {
          200: {
            description:
              "A JSON Object containing your session ID, the sessionId is needed to create a case",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/SessionResponse",
                },
              },
            },
          },
        },
      },
    },
    "/token": {
      post: {
        tags: ["preflight"],
        summary: "Returns token for current session",
        description: "",
        requestBody: {
          description: "Get token for your session",
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Token",
              },
            },
          },
        },
        responses: {
          200: {
            description:
              "A JSON Object containing your session ID, the sessionId is needed to create a case",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/TokenResponse",
                },
              },
            },
          },
        },
      },
    },
    "/case": {
      post: {
        parameters: [
          {
            in: "header",
            name: "X-Authorization",
            schema: {
              type: "string",
              example: "your-token-from-token-endpoint",
            },
            required: true,
          },
        ],
        tags: ["preflight"],
        summary: "Returns JSON object containg your case details",
        description: "",
        requestBody: {
          description: "Get token for your session",
          required: true,
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/Case",
              },
            },
          },
        },
        responses: {
          200: {
            description:
              "A JSON Object containing your session ID, the sessionId is needed to create a case",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CaseResponse",
                },
              },
            },
          },
        },
      },
    },
    "/detaileddecision/{CASE_ID}": {
      post: {
        parameters: [
          {
            in: "header",
            name: "X-Authorization",
            schema: {
              type: "string",
              example: "your-token-from-token-endpoint",
            },
            required: true,
          },
          {
            in: "path",
            name: "CASE_ID",
            schema: {
              type: "string",
              example: "your-case-id-from-case-endpoint",
            },
            required: true,
          },
        ],
        tags: ["preflight"],
        summary:
          "Returns Case Status before id image is submittedn also called after id is submitted to get the final decision on the id",
        description: "",

        responses: {
          200: {
            description:
              "A JSON Object containing your session ID, the sessionId is needed to create a case",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/DetailedResponse",
                },
              },
            },
          },
        },
      },
    },
  },
  components: {
    schemas: {
      KBA: {
        properties: {
          AnswerUrl: {
            type: "string",
            example: "n/a",
          },
          MultiPassQuestions: {
            type: "array",
            items: { type: "string" },
          },
          MultiPassStatus: {
            type: "int",
            example: 0,
          },
          Questions: {
            type: "array",
            items: { type: "string" },
          },
          Status: {
            type: "int",
            example: -1,
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T18:01:42.4929195Z",
          },
        },
      },
      OTP: {
        properties: {
          AuthURL: {
            type: "string",
          },
          DeliveryType: {
            type: "int",
            example: 0,
          },
          Language: {
            type: "int",
            example: 0,
          },
          Passcode: {
            type: "string",
          },
          Status: {
            type: "int",
            example: 7,
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T18:01:42.4929195Z",
          },
        },
      },
      DocAuth: {
        properties: {
          Url: {
            type: "string",
            example: "https://t.com/SCKCRwqWFfT",
          },
          Status: {
            type: "int",
            example: 0,
          },
          OCRDataStatus: {
            type: "int",
            example: 0,
          },
          FailureReasons: {
            type: "array",
            items: {
              type: "string",
            },
          },
          FailedAlgorithms: {
            type: "array",
            items: {
              type: "string",
            },
          },
          OCRData: {
            type: "string",
          },
        },
      },
      Auth: {
        properties: {
          Id: {
            type: "string",
            example:
              "547c0976-edce-4571-a465-cdf8d0dec405|bb2356f1-a03d-47bd-aa4a-2419c7c08127",
          },
          KBA: {
            $ref: "#/components/schemas/KBA",
          },
          OTP: {
            $ref: "#/components/schemas/OTP",
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T18:01:42.4929195Z",
          },
          DocumentAuthentication: {
            $ref: "#/components/schemas/DocAuth",
          },
        },
      },
      Blacklist: {
        properties: {
          CaseType: {
            type: "int",
            example: 0,
          },
          WasAccountNumberHit: {
            type: "string",
          },
          WasBinHit: {
            type: "string",
          },
          WasCustomerIdHit: {
            type: "string",
          },
          WasEmailDomainHit: {
            type: "string",
          },
          WasFullEmailAddressHit: {
            type: "string",
          },
          WasIPHit: {
            type: "string",
          },
          WasPostCodeHit: {
            type: "string",
          },
        },
      },
      Email: {
        properties: {
          ContainsDomainIssue: {
            type: "string",
          },
          ContainsMailboxIssue: {
            type: "string",
          },
          ContainsSyntaxIssue: {
            type: "string",
          },
          IsAgeHigh: {
            type: "string",
          },
          IsAgeLow: {
            type: "string",
          },
          IsAgeMedium: {
            type: "string",
          },
          IsDisposable: {
            type: "string",
          },
          IsDomainNotAllowed: {
            type: "string",
          },
          IsPopularityHigh: {
            type: "string",
          },
          IsPopularityLow: {
            type: "string",
          },
          IsPopularityMedium: {
            type: "string",
          },
          IsUserNameNotAllowed: {
            type: "string",
          },
          IsVelocityHigh: {
            type: "string",
          },
          IsVelocityLow: {
            type: "string",
          },
          IsVelocityMedium: {
            type: "string",
          },
        },
      },
      Customer: {
        properties: {
          CaseType: {
            type: "int",
            example: 0,
          },
          Email: {
            $ref: "#/components/schemas/Email",
          },
          HasBadHistory: {
            type: "boolean",
          },
          HasGoodHistory: {
            type: "boolean",
          },
          HasSuspiciousHistory: {
            type: "boolean",
          },
          IsNameAddressCombinationValid: {
            type: "boolean",
          },
          IsIDMVMatchingSummaryRiskHigh: {
            type: "boolean",
          },
          IsIDMVMatchingSummaryRiskMedium: {
            type: "boolean",
          },
          IsIDMVMatchingSummaryRiskLow: {
            type: "boolean",
          },
          IsReturningToPlatform: {
            type: "boolean",
          },
        },
      },
      Device: {
        properties: {
          IsFlashEnabled: {
            type: "boolean",
          },
          IsJavaEnabled: {
            type: "boolean",
          },
          IsJSEnabled: {
            type: "boolean",
          },
        },
      },
      GreyList: {
        properties: {
          CaseType: {
            type: "int",
            example: 0,
          },
          WasAccountNumberHit: {
            type: "boolean",
          },
          WasBinHit: {
            type: "boolean",
          },
          WasCustomerIdHit: {
            type: "boolean",
          },
          WasEmailDomainHit: {
            type: "boolean",
          },
          WasFullEmailAddressHit: {
            type: "boolean",
          },
          WasIPHit: {
            type: "boolean",
          },
          WasPostCodeHit: {
            type: "boolean",
          },
        },
      },
      Location: {
        properties: {
          BIN: {
            type: "string",
          },
          CaseType: {
            type: "int",
            example: 0,
          },
          IsIPCountryDomestic: {
            type: "boolean",
          },
        },
      },
      PhoneComputed: {
        properties: {
          IsPhoneRisky: {
            type: "boolean",
          },
        },
      },
      Transaction: {
        properties: {
          Email: {
            $ref: "#/components/schemas/Email",
          },
        },
      },
      matchesArray: {
        properties: {
          Timeunit: {
            type: "string",
            example: "days",
          },
          Timespan: {
            type: "string",
            example: "0,1",
          },
          Count: {
            type: "string",
            example: "100",
          },
        },
      },
      isConsumer: {
        properties: {
          Result: {
            type: "boolean",
          },
          Matches: {
            type: "array",
            items: {
              $ref: "#/components/schemas/matchesArray",
            },
          },
        },
      },
      Velocity: {
        properties: {
          CaseType: {
            type: "int",
            example: 0,
          },
          DecisionsWithin1Hour: {
            type: "int",
            example: 0,
          },
          DecisionsWithin24Hours: {
            type: "int",
            example: 55,
          },
          DecisionsWithin30Days: {
            type: "int",
            example: 100,
          },
          DecisionsWithin7Days: {
            type: "int",
            example: 100,
          },
          IsConsumerIdVelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsConsumerIdSsnVelocityHigh: {
            type: "boolean",
          },
          IsConsumerIdPostCodeVelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsConsumerIdPhoneNumberVelocityHigh: {
            type: "boolean",
          },
          IsConsumerIdIPVelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsConsumerIdEmailVelocityHigh: {
            type: "boolean",
          },
          IsConsumerIdDeviceIdVelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsConsumerIdAddressLine1VelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsConsumerIdAccountNumberVelocityHigh: {
            type: "boolean",
          },
          IsSsnVelocityHigh: {
            type: "boolean",
          },
          IsPhoneNumberVelocityHigh: {
            type: "boolean",
          },
          IsPostCodeVelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsIPVelocityHigh: {
            type: "boolean",
          },
          IsEmailVelocityHigh: {
            type: "boolean",
          },
          IsDeviceIdVelocityHigh: {
            type: "boolean",
          },
          IsAddressLine1VelocityHigh: {
            $ref: "#/components/schemas/isConsumer",
          },
          IsAccountNumberVelocityHigh: {
            type: "boolean",
          },
        },
      },
      extendedDevice: {
        properties: {
          Risk: {
            type: "string",
            example: "Low",
          },
          PredictiveMlRisk: {
            type: "string",
          },
          IsRegistrationRisky: {
            type: "boolean",
          },
        },
      },
      computedData: {
        properties: {
          BlackList: {
            $ref: "#/components/schemas/Blacklist",
          },
          CaseType: {
            type: "int",
            example: 0,
          },
          Customer: {
            $ref: "#/components/schemas/Customer",
          },
          Device: {
            $ref: "#/components/schemas/Device",
          },
          GreyList: {
            $ref: "#/components/schemas/GreyList",
          },
          Location: {
            $ref: "#/components/schemas/Location",
          },
          Phone: {
            $ref: "#/components/schemas/PhoneComputed",
          },
          Transaction: {
            $ref: "#/components/schemas/Transaction",
          },
          Velocity: {
            $ref: "#/components/schemas/Velocity",
          },
          WhiteList: {
            $ref: "#/components/schemas/Blacklist",
          },
          ExtendedDevice: {
            $ref: "#/components/schemas/extendedDevice",
          },
        },
      },
      rawCustomer: {
        properties: {
          Emails: {
            type: "array",
            items: {
              type: "string",
            },
          },
          FirstName: {
            type: "string",
          },
          LastName: {
            type: "string",
          },
          PhoneNumber: {
            type: "string",
          },
          SocialSecurityNumber: {
            type: "string",
          },
        },
      },
      rawData: {
        properties: {
          BINInformation: {
            type: "string",
          },
          Browser: {
            type: "string",
          },
          CaseType: {
            type: "int",
            example: 0,
          },
          Customer: {
            $ref: "#/components/schemas/rawCustomer",
          },
          DeviceTag: {
            type: "string",
          },
          IPAddresses: {
            type: "array",
            items: {
              type: "string",
            },
          },
          Location: {
            type: "string",
          },
          NavigatorLanguage: {
            type: "string",
          },
          NavigatorOscpu: {
            type: "string",
          },
          NavigatorPlugins: {
            type: "string",
          },
          OS: {
            type: "string",
          },
          ScreenColorDepth: {
            type: "string",
          },
          ScreenHeight: {
            type: "string",
          },
          ScreenWidth: {
            type: "string",
          },
          Transaction: {
            type: "string",
          },
          TrustevCustomerId: {
            type: "string",
            example: "xxxx-xxxx-xxx-xxxx",
          },
        },
      },
      DetailedResponse: {
        properties: {
          Authentication: {
            $ref: "#/components/schemas/Auth",
          },
          AuthenticatedDataRequest: {
            type: "string",
          },
          CaseId: {
            type: "string",
            example:
              "547c0976-edce-4571-a465-cdf8d0dec405|bb2356f1-a03d-47bd-aa4a-2419c7c08127",
          },
          CaseNumber: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405-case",
          },
          TruValidateConsumerId: {
            type: "string",
          },
          TransactionProcessingTime: {
            type: "string",
            example: "1885",
          },
          ComputedData: {
            $ref: "#/components/schemas/computedData",
          },
          RawData: {
            $ref: "#/components/schemas/rawData",
          },
          ExtendedDeviceInformation: {
            type: "string",
          },
          IdentityVerificationPayloadUrl: {
            type: "string",
          },
          Comment: {
            type: "string",
            example: "Decision for Default",
          },
          Confidence: {
            type: "int",
            example: 53,
          },
          Id: {
            type: "string",
          },
          Result: {
            type: "int",
            example: 3,
          },
          Score: {
            type: "int",
            example: 1006,
          },
          SessionId: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405",
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T18:01:42.4193341Z",
          },
          Type: {
            type: "int",
            example: 0,
          },
          Version: {
            type: "int",
            example: 1,
          },
          PlatformVersion: {
            type: "string",
            example: "V1",
          },
        },
      },
      CaseResponse: {
        properties: {
          Id: {
            type: "string",
            example:
              "547c0976-edce-4571-a465-cdf8d0dec405|bb2356f1-a03d-47bd-aa4a-2419c7c08127",
          },
          CaseNumber: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405-case",
          },
          CaseType: {
            type: "int",
            example: 0,
          },
          MarketType: {
            type: "int",
            example: 0,
          },
          LocationConsentId: {
            type: "string",
          },
          IdentityConsentId: {
            type: "string",
          },
          TransactionProcessingTime: {
            type: "string",
            example: "110",
          },
          Payments: {
            type: "array",
            items: {
              type: "string",
            },
          },
          SessionId: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405",
          },
          Statuses: {
            type: "array",
            items: { $ref: "#components/schemas/Status" },
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T17:43:14.0551462Z",
          },
          Transaction: {
            type: "string",
          },
        },
      },
      Status: {
        properties: {
          Comment: {
            type: "string",
            example: "Status added by Trustev to set current status to placed",
          },
          Id: {
            type: "string",
            example: "a331fd84-ec63-4dd8-a8f5-77158e936d92",
          },
          Status: {
            type: "int",
            example: 8,
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T17:43:14.0551462Z",
          },
        },
      },
      Case: {
        properties: {
          CaseNumber: {
            type: "string",
            example: "YourSessionId-Case",
          },
          SessionId: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405",
          },
        },
      },
      TokenResponse: {
        properties: {
          APIToken: {
            type: "string",
            example: "XXXXX-XXXX-XXXX-XXXX-XXXXXXX",
          },
          CredentialType: {
            type: "int",
            example: 1,
          },
          ExpireAt: {
            type: "string",
            example: "2022-07-06T18:10:00.8501523Z",
          },
        },
      },
      Token: {
        properties: {
          username: {
            type: "string",
            example: "your-username-given-to-you-by-doc-verification",
          },
          passwordhash: {
            type: "string",
            example: "super secret hashed password",
          },
          usernamehash: {
            type: "string",
            example: "super secret hashed username",
          },
          timestamp: {
            type: "string",
            example: "2022-07-06T17:08:53.219019Z",
          },
        },
        type: "object",
      },
      Session: {
        properties: {
          Details: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Ip",
            },
          },
          SessionType: {
            type: "int",
            example: 0,
          },
        },
        type: "object",
      },
      SessionResponse: {
        properties: {
          Details: {
            type: "array",
            items: {
              $ref: "#components/schemas/sessionDetailsResponse",
            },
          },
          Devices: {
            type: "array",
            items: {
              type: "string",
            },
          },
          Locations: {
            type: "array",
            items: {
              type: "string",
            },
          },
          BlackBox: {
            type: "string",
          },
          SessionId: {
            type: "string",
            example: "547c0976-edce-4571-a465-cdf8d0dec405",
          },
          SessionType: {
            type: "int",
            example: 0,
          },
        },
      },
      sessionDetailsResponse: {
        properties: {
          Browser: {
            type: "string",
          },
          ClientIP: {
            type: "string",
            example: "1.1.1.1",
          },
          Host: {
            type: "string",
          },
          Id: {
            type: "string",
          },
          OS: {
            type: "string",
          },
          Referer: {
            type: "string",
          },
          Timestamp: {
            type: "string",
            example: "2022-07-06T17:08:53.219019Z",
          },
          UserAgent: {
            type: "string",
          },
        },
      },

      Ip: {
        properties: {
          ClientIp: {
            type: "string",
            example: "1.1.1.1",
          },
        },
      },
    },
    securitySchemes: {
      public_key: {
        type: "apiKey",
        name: "X-PublicKey",
        in: "header",
      },
    },
  },
};
