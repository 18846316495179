/**
 * Avatar, Name, Email component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
//components
import AvatarName from "../AvatarName/AvatarName";
//styles
import {
  AvatarEmailItem as EmailItem,
  AvatarNameItem as NameItem,
  AvatarItem as Item,
} from "./AvatarNameEmail.styles";
//context
import { LoginContext } from "../../context/LoginContext";

import { Auth } from "aws-amplify";

const AvatarNameEmail = () => {
  const { userFullName, userEmail, currentUserObject } =
    useContext(LoginContext);

  const stringToColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  useEffect(() => {
    const resp = async () => {
      const data = await Auth.currentCredentials();
    };
    resp();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={1.75}>
          <Item>
            <AvatarName
              userFullName={userFullName}
              styles={{
                width: 56,
                height: 56,
                fontSize: "1.5rem",
                background: `#${stringToColor()}`,
              }}
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <NameItem>{userFullName}</NameItem>
            <EmailItem>{userEmail}</EmailItem>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AvatarNameEmail;
