/**
 * Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

export const newPassStyles = {
  formControl: { width: "50ch", margin: "1" },
  usernamePasswordIcons: {
    color: "white",
  },
  usernamePasswordText: {
    color: "white",
    fontFamily: "Oswald",
  },
};

export const NewPasswordItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
  marginLeft: "2rem",
}));

export const NewPasswordButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  paddingTop: "2rem",
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
  paddingLeft: "2rem",
}));

export const NewPasswordInputField = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Oswald", "sans-serif"].join(","),
    "&:focus": {
      boxShadow: `white`,
      borderColor: "white",
    },
  },
}));

export const NewPasswordButton = styled(Button)(({ theme }) => ({
  color: "white",
  height: "2.5rem",
  width: "15rem",
  backgroundColor: "#ade8f4",
  "&:hover": {
    backgroundColor: "#48cae4",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));
