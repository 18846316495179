/**
 * Avatar component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import * as React from "react";
import Avatar from "@mui/material/Avatar";

const AvatarName = ({ userFullName, styles }) => {
  const stringAvatar = (name) => {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };
  return <Avatar {...stringAvatar(userFullName)} sx={styles} />;
};

export default AvatarName;
