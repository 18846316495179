/**
 * Api Calls Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React from "react";

import Box from "@mui/material/Box";

const PrettyPrintJson = ({ data }) => (
  <div style={{ padding: "1rem" }}>
    <pre
      style={{
        color: "white",
        fontWeight: 700,
        fontFamily: "Consolas,monaco,monospace",
        fontSize: "1rem",
      }}
    >
      {JSON.stringify(data, null, 2)}
    </pre>
  </div>
);

const BodyBox = ({ body }) => {
  return (
    <Box
      sx={{
        borderRadius: "0.25rem",
        minHeight: "20rem",
        maxHeight: "30rem",
        width: "70%",
        background: "black",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",

        overflowX: "auto",
        overflowY: "scroll",
      }}
    >
      {<PrettyPrintJson data={body} />}
    </Box>
  );
};

export default BodyBox;
