/**
 * Welcome User Header Component Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const WelcomeItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  marginTop: "3.75rem",
  height: "2.5rem",
  color: "#013a63",
  paddingLeft: "2rem",
  paddingRight: "10rem",
  fontSize: "1.75rem",
  fontFamily: "Oswald",
  boxShadow: "none",
}));

export const WelcomeSubItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  height: "1.75rem",
  color: "#979dac",
  paddingLeft: "2rem",
  paddingRight: "10rem",
  fontSize: "1.1rem",
  fontFamily: "Oswald",
  boxShadow: "none",
}));
