/**
 * Api Calls syling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
export const apiCallStyles = {
  callHeader: {
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    color: "#1e6091",
    marginBottom: "1rem",
  },
};
