/**
 * Flow Details Table Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//components
//styles
import { flowDetailsTableStyles } from "./FlowDetailsTable.styles";
//context
import { FlowContext } from "../../context/FlowContext";

const FlowDetailsTable = () => {
  const { selectedFlow } = useContext(FlowContext);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        width: "30vw",
        backgroundColor: "rgba(144, 224, 239, 0.2)",
        "@media (min-width:1919px)": {
          // eslint-disable-line no-useless-computed-key
          width: "22vw",
        },
        marginTop: "1vh",
      }}
    >
      <Table sx={flowDetailsTableStyles.tableStyles} aria-label="simple table">
        <TableHead />
        <TableBody>
          <TableRow key="flowName">
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              Flow Name
            </TableCell>
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              {selectedFlow.flowName}
            </TableCell>
          </TableRow>
          <TableRow key="flowStyles">
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              Flow Agency Styles
            </TableCell>
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              {selectedFlow.flowAgency.name}
            </TableCell>
          </TableRow>
          <TableRow key="flowNotes">
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              Flow Notes
            </TableCell>
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              {selectedFlow.flowNotes.length > 0
                ? selectedFlow.flowNotes
                : "no notes found for this flow"}
            </TableCell>
          </TableRow>
          <TableRow key="flowSuccess">
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              Flow Verifciation Outcome
            </TableCell>
            <TableCell align="left" sx={flowDetailsTableStyles.rowStyles}>
              {selectedFlow.flowSuccessPath}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlowDetailsTable;
