/**
 * Login Context
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { createContext } from "react";

/**
 * this context is in charge of updating the aws user signed in
 * initially set to not logged in, and the username is empty
 */
export const LoginContext = createContext({
  resetLogin: () => {},
  currentUserObject: {},
  updateCurrentUserObject: () => {},
  userMustEnterOTP: false,
  updateUserMustEnterOTP: () => {},
  userMustChangePassword: false,
  updateUserMustChangePassword: () => {},
  userLoggedIn: false,
  updateUserLoggedIn: () => {},
  username: "",
  updateUsername: () => {},
  userGroups: [],
  updateUserGroups: () => {},
  userEmail: "",
  updateUserEmail: () => {},
  userFullName: "",
  updateUserFullName: () => {},
  userPhoneNumber: "",
  updateUserPhoneNumber: () => {},
  showLoginPopUp: false,
  updateShowLoginPopUp: () => {},
  apiDetails: {},
  updateApiDetails: () => {},
});
