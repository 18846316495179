/**
 * Flows Details Table component
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
export const flowDetailsTableStyles = {
  tableStyles: {
    backgroundColor: "transparent",
    width: "100%",
    "& .MuiTableCell-root": {
      border: "0px",
    },
    boxShadow: "none",
    "& .MuiTableRow-root:hover": {},
  },
  flowLastDemoDate: {
    fontFamily: "Oswald",
    fontSize: "0.75rem",
    color: "#6c757d",
  },
  rowStyles: {
    fontFamily: "Oswald",
    fontSize: "0.85rem",
    width: 800,
    color: "#013a63",
  },
};
