import { Card, Box, TableRow, CardHeader, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Item = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "black",
  boxShadow: "none",
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "rgba(202, 240, 248, 0.4)",
  margin: "10px",
  padding: "10px",
  width: "100%",
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#f2f9ff!important",
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  textAlign: "left",
  "& .MuiCardHeader-content": {
    "& .MuiTypography-root": {
      fontSize: "1.5rem",
      lineHeight: "1.334",
    },
  },
}));

export const StyledFlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "flex-start",
  padding: "20px 40px",
}));
