import { Box, Tabs, Tab } from "@mui/material";
import { useContext, useState } from "react";
import PropTypes from "prop-types";

//context
import { DemoFlowContext } from "../../context/DemoFlowContext";

//components
import ApiCalls from "../ApiCalls/ApiCalls";

//utils
import { isEmpty } from "../../utils/commonFunctions/commonFunctions";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function AllApiCalls(props) {
  const {
    allCallsMade: docVerApiCalls,
    idVerCallsMade: idVerApiCalls,
    otpCallsMade: otpApiCalls,
  } = useContext(DemoFlowContext);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab value={0} label="Document Verification" {...a11yProps(0)} />
        <Tab value={1} label="Identity Verification" {...a11yProps(1)} />
        <Tab value={2} label="One Time Passcode" {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {!isEmpty(docVerApiCalls) ? (
          <ApiCalls
            calls={docVerApiCalls}
            headerText={"All calls made to transunion server"}
          />
        ) : (
          <></>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {!isEmpty(idVerApiCalls) ? (
          <ApiCalls
            calls={idVerApiCalls}
            headerText={"All calls made to transunion server"}
          />
        ) : (
          <></>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        {!isEmpty(otpApiCalls) ? (
          <ApiCalls
            calls={otpApiCalls}
            headerText={"All calls made to transunion server"}
          />
        ) : (
          <></>
        )}
      </CustomTabPanel>
    </div>
  );
}
