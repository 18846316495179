export const navigationStyles = {
  icons: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    justifyContent: "center",
    minHeight: "2rem",
  },
  logo: {
    paddingTop: "1rem",
    paddingBottom: "12rem",
    justifyContent: "center",
  },
  drawerStyle: {
    width: 90,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 100,
      boxSizing: "border-box",
    },
    textAlign: "center",
  },
};
