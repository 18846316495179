import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "42px",
  width: "100%",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexWrap: "wrap",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  margin: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: "1rem!important",
  },
}));
