/**
 * Flow Context
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import { createContext } from "react";

/**
 * flows: [{...selectedFlowObject....}, {....selectedFlowObject....}, .....]
 * selectedFlow: {flowID, flowName, flowSuccessPath, flowAgency, flowNotes, creationDate, updateDate }
 * flowInPlayConfig: [
    {
      route: `Consent/:flowId`,
      component: (
        <ConsentPage
          nextPageLink={`../DocVerify/${flowId}`}
          previousPageLink={`../Dashboard`}
        />
      ),
    },
    {
      .......
    },
    {
      ........
    }
  ]
 *
 */
export const FlowContext = createContext({
  flows: [],
  updateFlows: () => {},
  selectedFlow: {},
  updateSelectedFlow: () => {},
  playSelectedFlow: false,
  updatePlaySelectedFlow: () => {},
  flowInPlayConfig: [],
  updateFlowInPlayConfig: () => {},
  showPlayFlowModal: false,
  updateShowPlayFlowModal: () => {},
});
