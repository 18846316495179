/**
 * Flows Icon  component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React from "react";
import Avatar from "@mui/material/Avatar";
//icons
import AirIcon from "@mui/icons-material/Air";

const FlowIcon = ({ flowIconHeight, flowIconWidth, flowIconSize }) => {
  const stringToColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <Avatar
      variant="rounded"
      sx={{
        background: `#${stringToColor()}`,
        height: flowIconHeight,
        width: flowIconWidth,
      }}
    >
      <AirIcon sx={{ fontSize: flowIconSize }} />
    </Avatar>
  );
};

export default FlowIcon;
