/**
 * Calendar Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//library imports
import React from "react";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Calendar = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CalendarPicker onChange={() => {}}></CalendarPicker>
      </LocalizationProvider>
    </Box>
  );
};
export default Calendar;
