/**
 * Dashboard Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useEffect, useState, useContext } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
//components
import AvatarNameEmail from "../../components/AvatarNameEmail/AvatarNameEmail";
import Calendar from "../../components/Calendar/Calendar";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import FlowsTable from "../../components/FlowsTable/FlowsTable";
import PlayFlowModal from "../../components/PlayFlowModal/PlayFlowModal";
//configs
import { agencies } from "../../utils/constants/agencies";
//services
import { getFlows } from "../../services/Flows/FlowServices";
//styles
import {
  DashboardItemDivider,
  DashboardPageItemAvatar,
  DashboardPageItemCalendar,
  DashboardItemCard,
  dashboardCardStyles,
  DashboardItemMostRecentFlows,
  dashboardPageStyles,
} from "./Dashboard.styles";
//component imports
import NavigationDrawer from "../../components/NavigationDrawer/NavigationDrawer";
import WelcomeUserHeader from "../../components/WelcomeUserHeader/WelcomeUserHeader";
//context
import { FlowContext } from "../../context/FlowContext";
//services
import {
  getNumFlowsDemoed,
  getNumFlowsUndemoed,
} from "../../services/Flows/FlowServices";
import { agencyWelcomeStyles } from "../AgencyWelcomePage/AgencyWelcomePage.styles";

const Dashboard = () => {
  const { updateFlows } = useContext(FlowContext);

  const [numInProgressFlows, setNumInProgressFlows] = useState(0);
  const [numCompletedFlows, setNumCompletedFlows] = useState(0);
  const numInProgressCardBody = "Flows Awaiting Demo";
  const numCompletedCardBody = "Completed Demos";

  useEffect(() => {
    const getCardInfo = async () => {
      let numFlowsDemoed = await getNumFlowsDemoed();
      setNumCompletedFlows(numFlowsDemoed);
      let numFlowsUndemoed = await getNumFlowsUndemoed();
      setNumInProgressFlows(numFlowsUndemoed);
    };
    getCardInfo();

    const getFlowsFromDynamo = async () => {
      let flowsInDB = await getFlows();
      updateFlows(flowsInDB);
    };
    getFlowsFromDynamo();
  }, [updateFlows]);

  return (
    <Grid item xs={12} sm container>
      <NavigationDrawer />
      <Grid item xs={12} sm container>
        <Grid item xs={8} container direction="column">
          <Grid item>
            <WelcomeUserHeader />
            <PlayFlowModal />
            <DashboardItemCard>
              <Grid
                item
                xs={12}
                sx={{ marginLeft: 3 }}
                container
                direction="row"
              >
                <Grid item xs={7} container direction="row">
                  <DashboardCard
                    cardStyle={dashboardCardStyles.cardStyleBlue}
                    icon={
                      <DriveFileRenameOutlineIcon
                        sx={{
                          borderRadius: "12%",
                          fontSize: "1.5rem",
                          background: "rgba(173, 181, 189, 0.25)",
                          padding: "0.25rem",
                        }}
                      />
                    }
                    header={numInProgressFlows}
                    body={numInProgressCardBody}
                  />
                  <DashboardCard
                    cardStyle={dashboardCardStyles.cardStyleDarkBlue}
                    icon={
                      <LibraryAddCheckIcon
                        sx={{
                          borderRadius: "12%",
                          border: "1px solid adb5bd",
                          fontSize: "1.5rem",
                          background: "rgba(173, 181, 189, 0.5)",
                          padding: "0.25rem",
                        }}
                      />
                    }
                    header={numCompletedFlows}
                    body={numCompletedCardBody}
                  />
                </Grid>
                <Grid item xs={4} container direction="column">
                  <Typography
                    sx={{
                      fontSize: "1.85rem",
                      fontFamily: "Oswald",
                      color: "#979dac",
                      fontVariant: "small-caps",
                      paddingTop: "3.5rem",
                    }}
                  >
                    agencies available for demo
                  </Typography>
                  <AvatarGroup
                    max={10}
                    sx={{
                      marginRight: "8rem",
                      fontFamily: "Oswald",
                      fontWeight: 400,
                      "@media (min-width:1919px)": {
                        // eslint-disable-line no-useless-computed-key
                        marginRight: "35rem",
                      },
                    }}
                  >
                    {agencies.map((currAgency, index) => {
                      return currAgency.value === "IRS" ? (
                        <Avatar
                          key={`${currAgency}_${index}`}
                          sx={{
                            width: 56,
                            height: 56,
                            background: "blue",
                          }}
                        >
                          <img
                            key={currAgency.logo}
                            src={currAgency.logo}
                            height="40"
                            width="40"
                            padding="0"
                            border="0"
                            alt="Create an account with ID.me"
                          />
                        </Avatar>
                      ) : currAgency.value === "NOTGOV" ? (
                        <Avatar
                          key={`${currAgency}_${index}`}
                          variant="circle"
                          sx={{
                            background: `#4cc9f0`,
                            height: 56,
                            width: 56,
                            marginLeft: "14rem",
                          }}
                        >
                          <LocalFireDepartmentIcon
                            sx={{ fontSize: "2.5rem" }}
                          />
                        </Avatar>
                      ) : (
                        <Avatar
                          key={`${currAgency}_${index}`}
                          alt={currAgency.name}
                          src={currAgency.logo}
                          sx={{
                            width: 56,
                            height: 56,
                          }}
                        />
                      );
                    })}
                  </AvatarGroup>
                </Grid>
              </Grid>
            </DashboardItemCard>
            <DashboardItemMostRecentFlows>
              <Typography sx={dashboardPageStyles.flowsHeader}>
                most recent flows{" "}
              </Typography>
              <DashboardItemDivider>
                <Divider variant="middle" />
              </DashboardItemDivider>
              <FlowsTable />
            </DashboardItemMostRecentFlows>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={3.5} container direction="column">
          <Grid item>
            <DashboardPageItemAvatar>
              <AvatarNameEmail />
            </DashboardPageItemAvatar>
            <DashboardPageItemCalendar>
              <Calendar />
            </DashboardPageItemCalendar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Dashboard;
