/**
 * Mfa styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const MfaItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
}));

export const MfaButton = styled(Button)(({ theme }) => ({
  color: "white",
  height: "2.5rem",
  width: "10rem",
  backgroundColor: "#ade8f4",
  "&:hover": {
    backgroundColor: "#48cae4",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));
