/**
 * Id Verification Page, shows api call in action
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Avatar,
  Button,
  CardContent,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import { blue } from "@mui/material/colors";

//context
import { DemoFlowContext } from "../../context/DemoFlowContext";
import { FlowContext } from "../../context/FlowContext";
import { LoginContext } from "../../context/LoginContext";

//services
import {
  getSession,
  getToken,
  getCase,
  getDecision,
  getIdentityPayload,
  buildCustomerObject,
  formatSessionResponse,
  formatTokenResponse,
  formatCaseResponse,
  formatDecisionResponse,
  formatIdentityPayloadResponse,
} from "../../services/IDVerification/IDVerification";
import { getNextPageLink } from "../../utils/commonFunctions/commonFunctions";

//css
import {
  Item,
  StyledCard,
  StyledCardHeader,
  StyledFlexBox,
} from "./IdVerApiPage.styles";

//component imports
import AgencyHeader from "../../components/AgencyHeader/AgencyHeader";
import { Footer } from "../../components/Footer/Footer";
import { ResponseDialog } from "../../components/ResponseDialog/ResponseDialog";
import { IdVerInputDisplayTable } from "../../components/IdVerInputDisplayTable/IdVerInputDisplayTable";

const IdVerApiPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { demoUserInfo, allCallsMade, resetDemo, updateIdVerCallsMade } =
    useContext(DemoFlowContext);
  const { flowInPlayConfig, selectedFlow } = useContext(FlowContext);
  const { flowSuccessPath } = selectedFlow;
  const nextPageLink = getNextPageLink(flowInPlayConfig, location.pathname);

  const [callsStarted, setCallsStarted] = useState(false);
  const [callsEnded, setCallsEnded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idFinalStatus, setIdFinalStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const { apiDetails } = useContext(LoginContext);
  const { idmv_otp_details } = apiDetails;
  const { OCRData, OCRDataStatus } = allCallsMade.call5?.endpointResponse
    ?.Authentication?.DocumentAuthentication ?? {
    OCRData: {},
    OCRDataStatus: 2,
  };

  const startOver = () => {
    resetDemo();
    navigate(flowInPlayConfig[0]);
  };

  const handleSubmit = async (event) => {
    const { OCRData } =
      allCallsMade.call5?.endpointResponse?.Authentication
        ?.DocumentAuthentication ?? {};
    const customerObject = buildCustomerObject(
      flowSuccessPath,
      demoUserInfo,
      OCRData
    );
    let sessionId,
      token,
      caseNumber,
      caseId = "";
    let decisionObj = {};

    setCallsEnded(false);
    setCallsStarted(true);
    const sessionResponse = await getSession(idmv_otp_details);
    updateIdVerCallsMade({
      call1: { ...formatSessionResponse(sessionResponse) },
    });
    if (sessionResponse.status === 200)
      sessionId = sessionResponse.data.SessionId;
    const tokenReponse = await getToken(idmv_otp_details);
    updateIdVerCallsMade({
      call2: { ...formatTokenResponse(tokenReponse) },
    });
    if (tokenReponse.status === 200) token = tokenReponse.data.APIToken;
    if (sessionId && token) {
      const caseResponse = await getCase(
        idmv_otp_details,
        { Customer: customerObject },
        token,
        sessionId
      );
      caseNumber = caseResponse.data.CaseNumber;
      caseId = caseResponse.data.Id;
      updateIdVerCallsMade({
        call3: { ...formatCaseResponse(caseResponse) },
      });
    }
    if (token && caseNumber && caseId) {
      decisionObj = await getDecision(idmv_otp_details, caseId, token);
      updateIdVerCallsMade({
        call4: { ...formatDecisionResponse(decisionObj) },
      });
    }
    if (decisionObj.status === 200 && token) {
      const identityUrl = decisionObj.data.IdentityVerificationPayloadUrl ?? "";
      if (identityUrl) {
        try {
          const idResponse = await getIdentityPayload(
            idmv_otp_details,
            identityUrl,
            token
          );
          console.log(idResponse);
          updateIdVerCallsMade({
            call5: { ...formatIdentityPayloadResponse(idResponse) },
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    console.log(decisionObj.data?.Result);
    console.log(typeof decisionObj.data?.Result);
    const status =
      Number(decisionObj.data?.Result) === 1
        ? "success"
        : Number(decisionObj.data?.Result) === 2
        ? "flag"
        : "failure";
    setIdFinalStatus(status);
    setDialogOpen(true);
    setCallsEnded(true);
  };

  useEffect(() => {
    if (callsStarted && !callsEnded) setLoading(true);
    else setLoading(false);
  }, [callsStarted, callsEnded, setLoading]);

  return (
    <div className="pageWrapper">
      <AgencyHeader />
      <div className="pageBody">
        <Item>
          <Typography variant="h2">Identity Verification</Typography>
        </Item>
        <Item>
          <Typography sx={{ fontSize: "22px" }}>
            The next step in the registration process is to verify your
            identity. Review the information below to ensure everything has been
            captured correctly before proceeding.
          </Typography>
        </Item>
        <StyledFlexBox>
          {OCRDataStatus === 1 && (
            <Item>
              <StyledCard>
                <StyledCardHeader
                  title="Document Capture Fields"
                  avatar={
                    <Avatar sx={{ bgcolor: blue[500] }}>
                      <CameraAltIcon />
                    </Avatar>
                  }
                />
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>{OCRData.FirstName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Last Name</TableCell>
                        <TableCell>{OCRData.LastName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Address Line 1</TableCell>
                        <TableCell>{OCRData.AddressLine1}</TableCell>
                      </TableRow>
                      {OCRData.AddressLine2 && (
                        <TableRow>
                          <TableCell>Address Line 2</TableCell>
                          <TableCell>{OCRData.AddressLine2}</TableCell>
                        </TableRow>
                      )}
                      {OCRData.AddressLine3 && (
                        <TableRow>
                          <TableCell>Address Line 3</TableCell>
                          <TableCell>{OCRData.AddressLine3}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>City</TableCell>
                        <TableCell>{OCRData.AddressCity}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell>{OCRData.AddressState}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Zip</TableCell>
                        <TableCell>
                          {OCRData.AddressPostalCode.substring(0, 5) +
                            "-" +
                            OCRData.AddressPostalCode.substring(5)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Date of Birth</TableCell>
                        <TableCell>
                          {dayjs(OCRData.DateOfBirth.substring(0, 10)).format(
                            "MM/DD/YYYY"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </StyledCard>
            </Item>
          )}
          <Item>
            <IdVerInputDisplayTable demoUserInfo={demoUserInfo} />
          </Item>
        </StyledFlexBox>
        <Item>
          <Typography sx={{ fontSize: "22px" }}>
            Click "Confirm" to continue with registration or click "Start Over"
            if the information is incorrect and you want to start the
            registration process over.
          </Typography>
        </Item>
        <StyledFlexBox>
          <Item>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={loading}
              loadingPosition="center"
              sx={{ minWidth: "160px" }}
            >
              Confirm
            </LoadingButton>
          </Item>
          <Item>
            <Button
              variant="contained"
              onClick={startOver}
              disabled={callsStarted}
              sx={{ backgroundColor: "grey!important" }}
            >
              Start Over
            </Button>
          </Item>
        </StyledFlexBox>
        <ResponseDialog
          verifyType="identity"
          status={idFinalStatus}
          setStatus={setIdFinalStatus}
          redirectLink={nextPageLink}
          open={dialogOpen}
          setOpen={setDialogOpen}
        />
      </div>
      <div className="pageFooter">
        <Footer />
      </div>
    </div>
  );
};

export default IdVerApiPage;
