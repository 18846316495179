/**
 * Authentication Services
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

/**
 *
 * @returns true if someone is logged in
 */
export const isLogin = async () => {
  let currUser = await getCurrentUser();
  if (currUser !== null) {
    return { isLoggedIn: true, username: currUser.username };
  } else {
    return { isLoggedIn: false, username: "" };
  }
};
/**
 * get the current logged in user, returns null if no one is logged in
 */
export const getCurrentUser = async () => {
  let currUser = null;
  await Auth.currentAuthenticatedUser({
    // Optional, By default is false.
    // If set to true, this call will send a request to Cognito to get the latest user data
    bypassCache: false,
  })
    .then((user) => {
      if (user !== null) currUser = user;
    })
    .catch((err) => {
      console.log("err =", err);
    });
  return currUser;
};
/**
 * sign in a user
 * @param {*} username
 * @param {*} password
 */
export const signIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    console.log("error = ", error);

    return null;
  }
};
/**
 * logout a user
 */
export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

/**
 * change the password when the user logs in for the first time to confirm the user in aws
 * @param {*} username format is some.user@transunion.com
 * @param {*} oldPassword temp password given to user
 * @param {*} newPassword password assigned by user
 */

export const changePasswordFirstTime = async (
  username,
  oldPassword,
  newPassword
) => {
  try {
    let user = await Auth.signIn(username, oldPassword);
    switch (user.challengeName) {
      case "NEW_PASSWORD_REQUIRED":
        try {
          let userUpdated = await Auth.completeNewPassword(user, newPassword);
          if (userUpdated !== undefined && userUpdated !== null) {
            return userUpdated;
          }
        } catch (error) {
          console.log("err = ", error);
        }
        break;
      default:
        console.log("no new pass required");
    }
  } catch (error) {
    console.log("error = ", error);
  }
};

export const confirmSignInMFA = async (user, code) => {
  const MFAType = "SMS_MFA";
  let newUser = {
    Username: user.username,
    Stoarge: user.storage,
    Pool: user.pool,
  };
  let currUser = new CognitoUser(newUser);
  currUser.Session = user.Session;
  // currUser.setSignInUserSession(user.Session);
  try {
    const loggedUser = await Auth.confirmSignIn(
      currUser, // Return object from Auth.signIn()
      code, // Confirmation code
      MFAType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );

    if (user !== undefined && user !== null) {
      return loggedUser;
    }
  } catch (error) {
    console.log("error in confirm mfa");
    console.log("error = ", error);
  }
};
