/**
 * Add Flow Modal Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

//styles
import { InvalidLoginPopupStyles } from "./InvalidLoginPopup.styles";

///context
import { LoginContext } from "../../context/LoginContext";

const InvalidLoginPopup = ({ textToRender }) => {
  const { updateShowLoginPopUp, showLoginPopUp } = useContext(LoginContext);

  const handleModalClose = async () => {
    updateShowLoginPopUp(false);
  };

  return (
    <Modal open={showLoginPopUp} onClose={handleModalClose}>
      <Box sx={InvalidLoginPopupStyles.modalBox}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <Typography sx={InvalidLoginPopupStyles.beforeInputText}>
                  {textToRender}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item sx={{ marginTop: "2rem" }}>
                <Button
                  sx={InvalidLoginPopupStyles.addFlowButton}
                  onClick={handleModalClose}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default InvalidLoginPopup;
