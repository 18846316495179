/**
 * agency header styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

export const AgencyStyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "agency",
})(({ agency }) => ({
  alignItems: "flex-start",
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    maxHeight: agency === "USDT" || "DOS" ? 64 : 64,
  },
  backgroundColor:
    agency === "IRS"
      ? "#00599c"
      : agency === "GSA"
      ? "#f2f9ff"
      : agency === "USDT"
      ? "#0053a2"
      : agency === "DOL"
      ? "white"
      : agency === "DOS"
      ? "#0a314d"
      : agency === "VA" || "NOTGOV"
      ? "#003e73"
      : "blue",
}));
