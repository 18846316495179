/**
 * param response header  styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

export const paramStyles = {
  parameters: {
    fontSize: "1.25rem",
    fontFamily: "Oswald",
    color: "#1e6091",
  },
  nameDescription: {
    fontSize: "1rem",
    fontFamily: "Oswald",
    color: "#403d39",
  },
};
