/**
 * Main Application Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

"use client";

//library imports
import React, { useState, useMemo, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

//aws
import { Amplify, API } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import awsconfig from "./aws-exports";

//page imports
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import Dashboard from "./pages/Dashboard/Dashboard";
import FlowPage from "./pages/FlowPage/FlowPage";
import ApiDocumentation from "./pages/ApiDocumentation/ApiDocumentation";
import AgencyWelcomePage from "./pages/AgencyWelcomePage/AgencyWelcomePage";
import DocVerApiPage from "./pages/DocVerApiPage/DocVerApiPage";
import IdVerApiPage from "./pages/IdVerApiPage/IdVerApiPage";
import OtpPage from "./pages/OtpPage/OtpPage";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import { ErrorFallbackPage } from "./pages/ErrorFallbackPage/ErrorFallbackPage";

//global context
import { LoginContext } from "./context/LoginContext";
import { FlowContext } from "./context/FlowContext";
import { DemoFlowContext } from "./context/DemoFlowContext";

//css imports
import "./App.css";

//Theme for @mui
import { ThemeProvider } from "@mui/material/styles";
import appTheme from "./utils/theme";

function App() {
  Amplify.configure(awsconfig);
  API.configure(awsconfig);
  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: "us-east-1",
      aws_pubsub_endpoint:
        "wss://a3ermkjx5bwouq-ats.iot.us-east-1.amazonaws.com/mqtt",
    })
  );

  const [currentUserObject, setCurrentUserObject] = useState({});
  const [userMustChangePassword, setUserMustChangePassword] = useState(false);
  const [userMustEnterOTP, setUserMustEnterOTP] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [showLoginPopUp, setShowLoginPopUp] = useState(false);
  const [flows, setFlows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState({});
  const [playSelectedFlow, setPlaySelectedFlow] = useState(false);
  const [flowInPlayConfig, setFlowInPlayConfig] = useState([]);
  const [demoUserInfo, setDemoUserInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    dob: "",
  });
  const [demoUserFinishedUpload, setDemoUserFinishedUpload] = useState(false);
  const [demoUserDetailedDecision, setDemoUserDetailedDecision] = useState({});
  const [allCallsMade, setAllCallsMade] = useState({});
  const [idVerCallsMade, setIdVerCallsMade] = useState({});
  const [otpCallsMade, setOtpCallsMade] = useState({});
  const [showPlayFlowModal, setShowPlayFlowModal] = useState(false);
  const [apiDetails, setApiDetails] = useState({});

  const updateShowPlayFlowModal = useCallback(() => {
    setShowPlayFlowModal(!showPlayFlowModal);
  }, [showPlayFlowModal]);
  const updateShowLoginPopUp = useCallback((newBool) => {
    setShowLoginPopUp(newBool);
  }, []);
  const updateAllCallsMade = useCallback((newCalls) => {
    setAllCallsMade((prev) => {
      return { ...prev, ...newCalls };
    });
  }, []);
  const updateIdVerCallsMade = useCallback((newCalls) => {
    setIdVerCallsMade((prev) => {
      return { ...prev, ...newCalls };
    });
  }, []);
  const updateOtpCallsMade = useCallback((newCalls) => {
    setOtpCallsMade((prev) => {
      return { ...prev, ...newCalls };
    });
  }, []);

  const updateDemoUserDetailedDecision = useCallback((bodyOfDecision) => {
    setDemoUserDetailedDecision(bodyOfDecision);
  }, []);

  const updateDemoUserInfo = useCallback(
    (newFields) => {
      setDemoUserInfo((prevState) => ({ ...prevState, ...newFields }));
    },
    [demoUserInfo]
  );

  const resetLogin = useCallback(() => {
    setCurrentUserObject({});
    setUserMustChangePassword(false);
    setUserMustEnterOTP(false);
    setUserLoggedIn(false);
    setUsername("");
    setUserGroups([]);
    setUserEmail("");
    setUserFullName("");
    setUserPhoneNumber("");
    setShowLoginPopUp(false);
  }, []);
  const updateDemoUserFinishedUpload = useCallback((bool) => {
    setDemoUserFinishedUpload(bool);
  }, []);

  const updateFlowInPlayConfig = useCallback((newConfig) => {
    setFlowInPlayConfig(newConfig);
  }, []);

  const updatePlaySelectedFlow = useCallback(() => {
    setPlaySelectedFlow(!playSelectedFlow);
  }, [playSelectedFlow]);

  const updateSelectedFlow = useCallback((flowClicked) => {
    setSelectedFlow(flowClicked);
  }, []);

  const updateFlows = useCallback((savedFlows) => {
    setFlows(savedFlows);
  }, []);

  const updateCurrentUserObject = useCallback((newUser) => {
    setCurrentUserObject((currentUserObject) => ({
      ...currentUserObject,
      ...newUser,
    }));
  }, []);
  const updateUserMustChangePassword = useCallback(() => {
    setUserMustChangePassword(!userMustChangePassword);
  }, [userMustChangePassword]);

  const updateUserMustEnterOTP = useCallback(() => {
    setUserMustEnterOTP(!userMustEnterOTP);
  }, [userMustEnterOTP]);

  const updateUserLoggedIn = useCallback(() => {
    setUserLoggedIn(!userLoggedIn);
  }, [userLoggedIn]);

  const updateUsername = useCallback((newUsername) => {
    setUsername(newUsername);
  }, []);

  const updateUserGroups = useCallback((newGroups) => {
    setUserGroups(newGroups);
  }, []);
  const updateUserEmail = useCallback((newEmail) => {
    setUserEmail(newEmail);
  }, []);
  const updateUserFullName = useCallback((newFullName) => {
    setUserFullName(newFullName);
  }, []);
  const updateUserPhoneNumber = useCallback((newPhoneNumber) => {
    setUserPhoneNumber(newPhoneNumber);
  }, []);
  const updateApiDetails = useCallback((newDetails) => {
    setApiDetails((prev) => {
      return { ...prev, ...newDetails };
    });
  }, []);
  const resetCalls = useCallback(() => {
    setAllCallsMade({});
    setOtpCallsMade({});
    setIdVerCallsMade({});
    setDemoUserFinishedUpload(false);
  });
  const resetDemo = useCallback(() => {
    resetCalls();
    setDemoUserInfo({
      firstName: "",
      lastName: "",
      phone: "",
      ssn: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      dob: "",
    });
  }, []);

  const loginContext = useMemo(() => {
    return {
      resetLogin,
      currentUserObject,
      updateCurrentUserObject,
      userMustChangePassword,
      updateUserMustChangePassword,
      userMustEnterOTP,
      updateUserMustEnterOTP,
      userLoggedIn,
      updateUserLoggedIn,
      username,
      updateUsername,
      userGroups,
      updateUserGroups,
      userEmail,
      updateUserEmail,
      userFullName,
      updateUserFullName,
      userPhoneNumber,
      updateUserPhoneNumber,
      showLoginPopUp,
      updateShowLoginPopUp,
      apiDetails,
      updateApiDetails,
    };
  }, [
    resetLogin,
    currentUserObject,
    updateCurrentUserObject,
    userMustChangePassword,
    updateUserMustChangePassword,
    userMustEnterOTP,
    updateUserMustEnterOTP,
    userLoggedIn,
    updateUserLoggedIn,
    username,
    updateUsername,
    userGroups,
    updateUserGroups,
    userEmail,
    updateUserEmail,
    userFullName,
    updateUserFullName,
    userPhoneNumber,
    updateUserPhoneNumber,
    showLoginPopUp,
    updateShowLoginPopUp,
    apiDetails,
    updateApiDetails,
  ]);
  const flowsContext = useMemo(() => {
    return {
      flows,
      updateFlows,
      selectedFlow,
      updateSelectedFlow,
      playSelectedFlow,
      updatePlaySelectedFlow,
      flowInPlayConfig,
      updateFlowInPlayConfig,
      showPlayFlowModal,
      updateShowPlayFlowModal,
    };
  }, [
    flows,
    updateFlows,
    selectedFlow,
    updateSelectedFlow,
    playSelectedFlow,
    updatePlaySelectedFlow,
    flowInPlayConfig,
    updateFlowInPlayConfig,
    showPlayFlowModal,
    updateShowPlayFlowModal,
  ]);

  const demoFlowContext = useMemo(() => {
    return {
      demoUserFinishedUpload,
      updateDemoUserFinishedUpload,
      demoUserDetailedDecision,
      updateDemoUserDetailedDecision,
      allCallsMade,
      updateAllCallsMade,
      demoUserInfo,
      updateDemoUserInfo,
      idVerCallsMade,
      updateIdVerCallsMade,
      otpCallsMade,
      updateOtpCallsMade,
      resetCalls,
      resetDemo,
    };
  }, [
    demoUserFinishedUpload,
    updateDemoUserFinishedUpload,
    demoUserDetailedDecision,
    updateDemoUserDetailedDecision,
    allCallsMade,
    updateAllCallsMade,
    demoUserInfo,
    updateDemoUserInfo,
    idVerCallsMade,
    updateIdVerCallsMade,
    otpCallsMade,
    updateOtpCallsMade,
    resetCalls,
    resetDemo,
  ]);

  return (
    <div>
      <ThemeProvider theme={appTheme}>
        <LoginContext.Provider value={loginContext}>
          <FlowContext.Provider value={flowsContext}>
            <DemoFlowContext.Provider value={demoFlowContext}>
              <Router>
                <Routes>
                  <Route path="/" element={<WelcomePage />} />
                  <Route path="Dashboard" element={<Dashboard />} />
                  <Route path="Flows" element={<FlowPage />} />
                  <Route path="Docs" element={<ApiDocumentation />} />
                  <Route
                    path="Demo/:flowId/:flowType/AgencyWelcome/:action"
                    element={<AgencyWelcomePage />}
                  />
                  <Route
                    path="Demo/:flowId/:flowType/DocVerify"
                    element={<DocVerApiPage />}
                  />
                  <Route
                    path="Demo/:flowId/:flowType/IdVerify"
                    element={<IdVerApiPage />}
                  />
                  <Route
                    path="Demo/:flowId/:flowType/otp"
                    element={<OtpPage />}
                  />
                  <Route
                    path="Demo/:flowId/:flowType/Success"
                    element={<SuccessPage />}
                  />
                  {playSelectedFlow ? (
                    flowInPlayConfig.map((currRoute, index) => {
                      return (
                        <Route
                          key={index}
                          path={currRoute.route}
                          element={currRoute.component}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Routes>
              </Router>
            </DemoFlowContext.Provider>
          </FlowContext.Provider>
        </LoginContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
