/**
 * Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Stack, Typography } from "@mui/material";
//css
import styles from "./WelcomePage.module.css";
//component imports
import { Login } from "../../components/Login/Login";
import { NewPassword } from "../../components/NewPassword/NewPassword";
import { Mfa } from "../../components/Mfa/Mfa";
import InvalidLoginPopup from "../../components/InvalidLoginPopup/InvalidLoginPopup";
//file imports
import tuMiniLogo from "../../images/tuMiniLogo.png";
//global context
import { LoginContext } from "../../context/LoginContext";
//services
import { getCurrentUser } from "../../services/Authentication";

const WelcomePage = () => {
  const {
    userLoggedIn,
    userMustChangePassword,
    userMustEnterOTP,
    currentUserObject,
    updateUserFullName,
    updateUserEmail,
    updateUserPhoneNumber,
  } = useContext(LoginContext);
  const [attemptedUsername, setAttemptedUsername] = useState("");
  const [attemptedPass, setAttemptedPass] = useState("");
  const navigate = useNavigate();
  const [showResetPass, setShowResetPass] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState(
    "Please enter your credentials below. If you do not have credentials and would like to access this system, please contact the sales engineering team of Public Sector."
  );

  useEffect(() => {
    const doubleCheckUserLoggedIn = async () => {
      if (userLoggedIn) {
        let amplifyUser = await getCurrentUser();
        if (amplifyUser.username === currentUserObject.username) {
          updateUserFullName(
            `${
              amplifyUser.attributes.given_name.charAt(0).toUpperCase() +
              amplifyUser.attributes.given_name.slice(1)
            } ${
              amplifyUser.attributes.family_name.charAt(0).toUpperCase() +
              amplifyUser.attributes.family_name.slice(1)
            }`
          );
          updateUserEmail(amplifyUser.attributes.email);
          updateUserPhoneNumber(amplifyUser.attributes.phoneNumber);
          navigate("../Dashboard", { replace: true });
        }
      }
    };
    doubleCheckUserLoggedIn();
  }, [
    userLoggedIn,
    updateUserEmail,
    updateUserPhoneNumber,
    updateUserFullName,
    navigate,
    currentUserObject,
  ]);

  useEffect(() => {
    if (userMustChangePassword) {
      setShowResetPass(true);
      setSubHeaderText(
        "Looks like this is your first time accessing IDS, please update your password. Passwords on line 1 and line 2 must match."
      );
    } else {
      setShowResetPass(false);
      setSubHeaderText(
        "Please enter your credentials below. If you do not have credentials and would like to access this system, please contact the sales engineering team of Public Sector."
      );
    }
  }, [userMustChangePassword]);

  useEffect(() => {
    if (userMustEnterOTP) {
      setShowOTP(true);
      setSubHeaderText(
        "Enter the passcode sent to your mobile device, if you did not recieve a code please contact the sales engineering team of Public Sector."
      );
    } else {
      setShowOTP(false);
    }
  }, [userMustEnterOTP]);

  let wrongPassword =
    "Your username or password is incorrect. Please try again or contact the admin within transunion public sector for this application to reset your password";
  let newPassDoesntMatch =
    "The new password you entered on line 1 does not match the new password you entered on line 2";
  return (
    <Grid sx={{ background: "white" }} container>
      <img className={styles.tuLogo} src={tuMiniLogo} alt="logo" />
      <InvalidLoginPopup
        textToRender={
          userMustChangePassword ? newPassDoesntMatch : wrongPassword
        }
      />
      <Box
        sx={{
          background: "#4cc9f0",
          height: "55vh",
          width: "40vw",
          marginTop: "25vh",
          marginLeft: "30%",
          borderRadius: "1rem",
        }}
      >
        <Stack direction="column">
          <Typography
            sx={{
              marginTop: "5vh",
              color: "white",
              fontFamily: "Oswald",
              fontSize: "2.8rem",
              textAlign: "center",
            }}
          >
            Welcome to IDS
          </Typography>
          <span className={styles.login_subheader}>{subHeaderText}</span>
          {showOTP ? (
            <Mfa username={attemptedUsername} password={attemptedPass} />
          ) : showResetPass ? (
            <NewPassword
              attemptedUsername={attemptedUsername}
              attemptedPass={attemptedPass}
            />
          ) : (
            <Login
              updateAttemptedUsername={setAttemptedUsername}
              updateAttemptedPassword={setAttemptedPass}
            />
          )}
        </Stack>
      </Box>
    </Grid>
  );
};
export default WelcomePage;
