/**
 * Add Flow Modal Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//icons
import CloseIcon from "@mui/icons-material/Close";
//components
//styles
import {
  AddFlowModalStyles,
  AddFlowModalTextField,
  AddFlowModalTextFieldMulitline,
} from "./AddFlowModal.styles";
//constants
import { agencies } from "../../utils/constants/agencies";
import { getFlowType } from "../../utils/constants/sucessPaths";
///context
import { FlowPageContext } from "../../context/FlowPageContext";
import { FlowContext } from "../../context/FlowContext";
//utils
import { createInitialFlowObject } from "../../utils/commonFunctions/commonFunctions";
//services
import { addFlowToDB, getFlows } from "../../services/Flows/FlowServices";

const AddFlowModal = () => {
  const { updateShowAddFlowModal, showAddFlowModal } =
    useContext(FlowPageContext);
  const { updateFlows } = useContext(FlowContext);
  const [newFlowName, setNewFlowName] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedSuccessPath, setSelectedSuccessPath] = useState("");
  const [notes, setNotes] = useState("");

  const handleModalClose = async () => {
    updateShowAddFlowModal();
    setNewFlowName("");
    setSelectedClient("");
    setSelectedSuccessPath("");
    setNotes("");
  };

  /*
  const successPaths = getSuccessPaths(
    AddFlowModalStyles.customStyleSuccess,
    AddFlowModalStyles.customStyleFail
  );
  */
  const successPaths = getFlowType();

  const handleClientChange = (event) => {
    let newSelectedClient = event.target.value;
    setSelectedClient(newSelectedClient);
  };
  const handleSuccessPathChange = (event) => {
    let newPath = event.target.value;
    setSelectedSuccessPath(newPath);
  };
  const updateNewFlowName = (event) => {
    let newName = event.target.value;
    setNewFlowName(newName);
  };
  const updateNotes = (event) => {
    let newNotes = event.target.value;
    setNotes(newNotes);
  };

  const addFlowClicked = async () => {
    //add the flow to db
    let newFlow = createInitialFlowObject(
      newFlowName,
      selectedSuccessPath,
      selectedClient,
      notes
    );
    await addFlowToDB(newFlow);
    let flowsInDB = await getFlows();
    updateFlows(flowsInDB);
    updateShowAddFlowModal();
    setNewFlowName("");
    setSelectedClient("");
    setSelectedSuccessPath("");
    setNotes("");
  };
  return (
    <Modal open={showAddFlowModal} onClose={handleModalClose}>
      <Box sx={AddFlowModalStyles.modalBox}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" xs={12} justifyContent="end">
              <Button
                variant="contained"
                sx={AddFlowModalStyles.exitButton}
                onClick={updateShowAddFlowModal}
              >
                <CloseIcon />
              </Button>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={AddFlowModalStyles.modalHeader}>
              Create New Flow
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <Typography sx={AddFlowModalStyles.beforeInputText}>
                  flow name
                </Typography>
              </Grid>
              <Grid item>
                <FormControl variant="outlined">
                  <AddFlowModalTextField
                    id="outlined-textfield-flow-name"
                    type="text"
                    value={newFlowName}
                    onChange={updateNewFlowName}
                    sx={AddFlowModalStyles.modalTextFieldText}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
            <Grid container>
              <Grid item>
                <Typography sx={AddFlowModalStyles.beforeInputText}>
                  demo client
                </Typography>
              </Grid>
              <Grid item>
                <FormControl sx={AddFlowModalStyles.selectStyle}>
                  <Select
                    id="client-options-select"
                    value={selectedClient}
                    label="Selected Client"
                    onChange={handleClientChange}
                    input={<AddFlowModalTextField />}
                  >
                    {agencies.map((currOption, index) => {
                      return (
                        <MenuItem
                          key={`${index}_${currOption.value}`}
                          value={currOption.value}
                          sx={AddFlowModalStyles.menuItemStyle}
                        >
                          <Grid container>
                            <Grid item xs={1}>
                              {currOption.name === "IRS" ? (
                                <Avatar
                                  sx={{
                                    width: 26,
                                    height: 26,
                                    background: "blue",
                                  }}
                                >
                                  <img
                                    src={currOption.logo}
                                    height="22"
                                    width="22"
                                    padding="0"
                                    border="0"
                                    alt="Create an account with ID.me"
                                  />
                                </Avatar>
                              ) : (
                                <Avatar
                                  key={index}
                                  alt={currOption.name}
                                  src={currOption.logo}
                                  sx={{
                                    width: 26,
                                    height: 26,
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={10}>
                              {currOption.name}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
            <Grid container>
              <Grid item>
                <Typography sx={AddFlowModalStyles.beforeInputText}>
                  verification success path
                </Typography>
              </Grid>
              <Grid item>
                <FormControl sx={AddFlowModalStyles.selectStyle}>
                  <Select
                    id="success-path-options-select"
                    value={selectedSuccessPath}
                    label="Selected Success Path"
                    onChange={handleSuccessPathChange}
                    input={<AddFlowModalTextField />}
                  >
                    {successPaths.map((currOption, index) => {
                      return (
                        <MenuItem
                          key={`${index}_${currOption.value}`}
                          value={currOption.value}
                          sx={AddFlowModalStyles.menuItemStyle}
                        >
                          <Grid container>
                            {/*
                            <Grid item xs={1}>
                              {currOption.icon}
                            </Grid>
                            */}
                            <Grid item xs={10}>
                              {currOption.name}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography sx={AddFlowModalStyles.beforeInputText}>
                      flow notes
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined">
                      <AddFlowModalTextFieldMulitline
                        multiline
                        id="outlined-textfield-flow-notes"
                        type="text"
                        value={notes}
                        onChange={updateNotes}
                        sx={AddFlowModalStyles.modalTextFieldText}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item sx={{ marginTop: "2rem" }}>
                    <Button
                      sx={AddFlowModalStyles.addFlowButton}
                      onClick={addFlowClicked}
                    >
                      Add Flow
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddFlowModal;
