/**
 * Api Documentation
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Grid from "@mui/material/Grid";
import { DocItem as Item } from "./ApiDocumentation.styles";
//components
import NavigationDrawer from "../../components/NavigationDrawer/NavigationDrawer";
//swagger doc
import { docs } from "./docVerifySwagger";

const ApiDocumentation = () => {
  return (
    <Grid item xs={12} sm container>
      <NavigationDrawer />
      <Grid item container>
        <Item>
          <SwaggerUI spec={docs} />
        </Item>
      </Grid>
    </Grid>
  );
};
export default ApiDocumentation;
