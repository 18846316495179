import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const QRCodeBox = styled(Box)(({ theme }) => ({
  minWidth: "300px",
  minHeight: "300px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignContent: "center",
  padding: "20px",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));
