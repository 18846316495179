/**
 * Flows Table sub component where name and logo of the flow are in it
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
export const flowTableNameLogoStyles = {
  flowNameHeader: {
    fontSize: "1.5rem",
    color: "#013a63",
    fontFamily: "Oswald",
    marginLeft: "0.5rem",
  },
  agencyHeader: {
    fontSize: "1rem",
    color: "#6c757d",
    fontFamily: "Oswald",
    marginTop: "0.25rem !important",
  },
  flowSuccess: {
    color: "green",
    width: 36,
    height: 36,
    marginLeft: "1rem",
  },
  flowFailure: {
    color: "#d90429",
    width: 36,
    height: 36,
    marginLeft: "1rem",
  },
};
