import { useForm } from "react-hook-form";
import {
  StyledFormLabel,
  StyledFormGroup,
  StyledButton,
  StyledItem,
} from "./AgencyRegisterForm.styles";
import { Grid, Link, Typography } from "@mui/material";
import { FormTextInput } from "../FormComponents/FormComponents";
import { useContext } from "react";
import { DemoFlowContext } from "../../context/DemoFlowContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export function AgencyRegisterForm(props) {
  const { handleSubmit: propsSubmit, flowType } = props;
  const { demoUserInfo, updateDemoUserInfo, resetCalls } =
    useContext(DemoFlowContext);
  const { control, handleSubmit } = useForm({
    defaultValues: { ...demoUserInfo },
  });

  const onSubmit = (data) => {
    updateDemoUserInfo(data);
    resetCalls();
    propsSubmit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup>
        <Typography variant="h5">Create an account for new users</Typography>
      </StyledFormGroup>
      {[1, 2, 4, 5].includes(+flowType) && (
        <div>
          <StyledFormGroup>
            <StyledFormLabel>First Name</StyledFormLabel>
            <FormTextInput
              variant="outlined"
              name="firstName"
              control={control}
              rules={{ required: true, minLength: 1 }}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormLabel>Last Name</StyledFormLabel>
            <FormTextInput
              variant="outlined"
              name="lastName"
              control={control}
              rules={{ required: true, minLength: 1 }}
            />
          </StyledFormGroup>
        </div>
      )}
      {[2, 5].includes(+flowType) && (
        <div>
          <StyledFormGroup>
            <StyledFormLabel>Address Line 1</StyledFormLabel>
            <FormTextInput
              variant="outlined"
              name="addressLine1"
              control={control}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledFormLabel>Address Line 2 (Apt/Suite/Unit)</StyledFormLabel>
            <FormTextInput
              variant="outlined"
              name="addressLine2"
              control={control}
            />
          </StyledFormGroup>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledFormGroup>
                <StyledFormLabel>City</StyledFormLabel>
                <FormTextInput
                  variant="outlined"
                  name="city"
                  control={control}
                />
              </StyledFormGroup>
            </Grid>
            <Grid item xs={2}>
              <StyledFormGroup>
                <StyledFormLabel>State</StyledFormLabel>
                <FormTextInput
                  variant="outlined"
                  name="state"
                  control={control}
                />
              </StyledFormGroup>
            </Grid>
            <Grid item xs={4}>
              <StyledFormGroup>
                <StyledFormLabel>Zipcode</StyledFormLabel>
                <FormTextInput
                  variant="outlined"
                  name="zip"
                  control={control}
                />
              </StyledFormGroup>
            </Grid>
          </Grid>
        </div>
      )}
      <StyledFormGroup>
        <StyledFormLabel>Phone Number</StyledFormLabel>
        <FormTextInput variant="outlined" name="phone" control={control} />
      </StyledFormGroup>
      {[1, 2, 4, 5].includes(+flowType) && (
        <div>
          <StyledFormGroup>
            <StyledFormLabel>SSN</StyledFormLabel>
            <FormTextInput
              variant="outlined"
              name="ssn"
              control={control}
              rules={{
                required: true,
                minLength: 9,
                maxLength: 9,
                pattern: "\\d{9}",
              }}
              type="password"
            />
          </StyledFormGroup>
        </div>
      )}
      {[1, 2, 5].includes(+flowType) && (
        <div>
          <StyledFormGroup>
            <StyledFormLabel>Date of Birth (MM/DD/YYYY)</StyledFormLabel>
            <FormTextInput variant="outlined" name="dob" control={control} />
          </StyledFormGroup>
        </div>
      )}
      <StyledButton size="large" variant="contained" type="submit">
        Submit
      </StyledButton>
      <hr />
      <StyledItem>
        <Link>
          Security Practices and Privacy Act Statement
          <OpenInNewIcon fontSize="small" />
        </Link>
      </StyledItem>
      <StyledItem>
        <Link>
          Privacy Act Statement
          <OpenInNewIcon fontSize="small" />
        </Link>
      </StyledItem>
    </form>
  );
}
