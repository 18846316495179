/**
 * Document Verifiation Api Page, shows api call in action
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//libary imports
import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { PubSub } from "aws-amplify";
import { Button, Typography, LinearProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//services
import {
  startPreFlight,
  getDecision,
} from "../../services/DocVerification/DocVerification";

//component imports
import AgencyHeader from "../../components/AgencyHeader/AgencyHeader";
import { QRCode } from "../../components/QRCode/QRCode";
import { Footer } from "../../components/Footer/Footer";
import { ResponseDialog } from "../../components/ResponseDialog/ResponseDialog";

//styles
import {
  docVerifyStyles,
  StyledBox,
  CallOutItem,
  ClearItem,
} from "./DocVerApiPage.styles";

//context
import { DemoFlowContext } from "../../context/DemoFlowContext";
import { FlowContext } from "../../context/FlowContext";

//utils
import {
  isEmpty,
  getNextPageLink,
} from "../../utils/commonFunctions/commonFunctions";
import { startListening } from "../../services/PubSub";

const DocVerApiPage = () => {
  const location = useLocation();
  const { flowInPlayConfig } = useContext(FlowContext);
  const {
    updateDemoUserFinishedUpload,
    demoUserFinishedUpload,
    updateAllCallsMade,
  } = useContext(DemoFlowContext);
  const nextPageLink = getNextPageLink(flowInPlayConfig, location.pathname);

  const [callsMade, setCallsMade] = useState({});
  const [token, setToken] = useState("");
  const [caseId, setCaseId] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [failureMessages, setFailureMessages] = useState([]);
  const [finalDecision, setFinalDecision] = useState(-1);
  const [docFinalStatus, setDocFinalStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  PubSub.subscribe("docVerificationUpload").subscribe({
    next: (data) => {
      console.log("Message received", data);
      if (data?.value?.CaseId === caseId) {
        console.log(
          "Case ID from message matches this flow.  Document verification completed.  Continue on with flow."
        );
        getFinalDecision();
      }
    },
    error: (error) => console.error(error),
    complete: () => console.warn("Done"),
  });

  const getFinalDecision = async () => {
    if (token !== "" && caseId !== "") {
      //pubSubWebhook.unsubscribe();
      const decisionCallDetails = await getDecision(token, caseId);
      updateAllCallsMade(decisionCallDetails);
      updateDemoUserFinishedUpload(true);

      console.log(decisionCallDetails.call5?.endpointResponse?.Result);
      const tempStatus = decisionCallDetails.call5?.endpointResponse?.Result;
      setFinalDecision(tempStatus);
      if (tempStatus > 1) {
        setFailureMessages(
          decisionCallDetails.call5?.endpointResponse?.Authentication
            ?.DocumentAuthentication?.FailureReasons ?? []
        );
        setDocFinalStatus("failure");
      } else setDocFinalStatus("success");
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    const preFlight = async () => {
      try {
        let response = await startPreFlight();
        setToken(response.call2.endpointResponse.APIToken);
        setCaseId(response.call3.endpointResponse.Id);
        setQrCode(
          response?.call4?.endpointResponse?.Authentication
            ?.DocumentAuthentication?.Url ?? ""
        );
        setCallsMade(response);
      } catch (error) {
        console.error("Error calling document verification apis", error);
      }
    };

    if (!qrCode) preFlight();
    //startListening();
  }, []);

  useEffect(() => {
    if (!isEmpty(callsMade)) updateAllCallsMade(callsMade);
  }, [callsMade, updateAllCallsMade]);

  return (
    <div className="pageWrapper">
      <div className="pageHeader">
        <AgencyHeader />
      </div>
      <div className="pageBody">
        <StyledBox>
          <CallOutItem>
            <Typography variant="h5" sx={docVerifyStyles.scanQRText}>
              To register for a new account, you need to provide valid
              identification documents. Use a mobile device to scan the QR code
              below to begin your document verification process. You will be
              taken to a new site to continue with document verification.
            </Typography>
          </CallOutItem>
        </StyledBox>
        <StyledBox>
          <Button variant="text" onClick={getFinalDecision}>
            <QRCode qrCode={qrCode} />
          </Button>
        </StyledBox>
        <StyledBox>
          {!demoUserFinishedUpload ? (
            <ClearItem elevation={0}>
              <Typography variant="h6">
                Waiting for document upload on mobile device...
              </Typography>
              <LinearProgress color="secondary" />
            </ClearItem>
          ) : (
            <StyledBox elevation={0}>
              <CheckCircleIcon fontSize="large" sx={{ color: "green" }} />
              <Typography variant="h6">Documents uploaded</Typography>
            </StyledBox>
          )}
        </StyledBox>
        <ResponseDialog
          verifyType="document"
          status={docFinalStatus}
          setStatus={setDocFinalStatus}
          redirectLink={nextPageLink}
          open={dialogOpen}
          setOpen={setDialogOpen}
          failureMessages={failureMessages}
        />
      </div>
      <div className="pageFooter">
        <Footer />
      </div>
    </div>
  );
};
export default DocVerApiPage;
