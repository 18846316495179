import { Grid, Paper, ToggleButtonGroup } from "@mui/material";
import { Item, SignInButton } from "./AgencySignIn.styles";
import { AgencyRegisterForm } from "../AgencyRegisterForm/AgencyRegisterForm";
import { useState } from "react";
import { AgencySignInForm } from "../AgencySignInForm/AgencySignInForm";

export function AgencySignIn(props) {
  const { handleSubmit, action, flowType } = props;
  const [buttonValue, setButtonValue] = useState(action);

  const handleButtonChange = (event, newValue) => {
    setButtonValue(newValue);
  };

  return (
    <Paper
      sx={{
        maxWidth: "500px",
        minWidth: "300px",
        margin: "40px",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
      }}
      elevation={3}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={buttonValue}
              onChange={handleButtonChange}
            >
              <SignInButton value="signin">Sign In</SignInButton>
              <SignInButton value="register">Create an account</SignInButton>
            </ToggleButtonGroup>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            {buttonValue === "register" ? (
              <AgencyRegisterForm
                handleSubmit={handleSubmit}
                flowType={flowType}
              />
            ) : (
              <AgencySignInForm />
            )}
          </Item>
        </Grid>
      </Grid>
    </Paper>
  );
}
