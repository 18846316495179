/**
 * Config for agencies available
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

import deptLaborLogo from "../../images/deptLabor.png";
import stateDeptLogo from "../../images/stateDept.png";
import vetAffairsLogo from "../../images/vetAffairs.png";
import treasuryDeptLogo from "../../images/treasuryDept.png";
import IRSLogo from "../../images/IRS.png";
import GSALogo from "../../images/gsa.png";

export const agencies = [
  {
    name: "U.S. DEPARTMENT OF LABOR",
    logo: deptLaborLogo,
    value: "DOL",
  },
  {
    name: "IRS",
    logo: IRSLogo,
    value: "IRS",
  },
  {
    name: "U.S. DEPARTMENT OF STATE",
    logo: stateDeptLogo,
    value: "DOS",
  },
  {
    name: "VETERANS AFFAIRS",
    logo: vetAffairsLogo,
    value: "VA",
  },
  {
    name: "U.S. DEPARTMENT OF THE TREASURY",
    logo: treasuryDeptLogo,
    value: "USDT",
  },
  {
    name: "General Services Administration",
    logo: GSALogo,
    value: "GSA",
  },
  {
    name: "NOT GOV CLIENT",
    value: "NOTGOV",
  },
];

export const availableAgencies = {
  DOL: {
    name: "U.S. DEPARTMENT OF LABOR",
    value: "DOL",
    logo: deptLaborLogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Source Sans Pro",
        fontSize: "1.5rem",
        color: "black",
        marginTop: "1.5rem",
        fontWeight: 700,
        marginLeft: "1rem",
      },
      logoStyle: {
        color: "white",
        marginTop: "0.5rem",
        height: 64,
        marginLeft: "15rem",
      },
    },
  },
  GSA: {
    name: "Login.gov Demo Site",
    value: "GSA",
    logo: GSALogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Source Sans Pro",
        fontSize: "1.5rem",
        color: "black",
        marginTop: "1.5rem",
        fontWeight: 700,
        marginLeft: "1rem",
        textAlign: "left",
      },
      logoStyle: {
        color: "white",
        margin: "5px",
        height: "48px",
        alignSelf: "center",
      },
    },
  },
  IRS: {
    name: "IRS",
    value: "IRS",
    logo: IRSLogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Tiro Devanagari Hindi",
        fontSize: "3.5rem",
      },
      logoStyle: {
        color: "white",
        marginTop: "0.5rem",
        height: 64,
        marginLeft: "15rem",
      },
    },
  },
  DOS: {
    name: "U.S. DEPARTMENT OF STATE",
    value: "DOS",
    logo: stateDeptLogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Tiro Devanagari Hindi",
        fontSize: "3.5rem",
      },
      logoStyle: {
        color: "white",
        marginTop: "0.5rem",
        height: 85,
        marginLeft: "15rem",
      },
    },
  },
  VA: {
    name: "VETERANS AFFAIRS",
    value: "VA",
    logo: vetAffairsLogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Tiro Devanagari Hindi",
        fontSize: "3.5rem",
      },
      logoStyle: {
        color: "white",
        marginTop: "0.5rem",
        height: 84,
        marginLeft: "15rem",
      },
    },
  },
  USDT: {
    name: "U.S. DEPARTMENT OF THE TREASURY",
    value: "USDT",
    logo: treasuryDeptLogo,
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Merriweather",
        fontSize: "2.2rem",
        fontWeight: 700,
        marginTop: "1.5rem",
        marginLeft: "1rem",
      },
      logoStyle: {
        color: "white",
        marginTop: ".75rem",
        height: 74,
        marginLeft: "15rem",
      },
    },
  },
  NOTGOV: {
    name: "NOT GOV CLIENT",
    value: "NOTGOV",
    styles: {
      agencyHeaderStyle: {
        flexGrow: 1,
        fontFamily: "Tiro Devanagari Hindi",
        fontSize: "1.8rem",
        marginTop: "1.5rem",
        marginLeft: "1rem",
        color: "white !important",
      },
      logoStyle: {
        color: "white",
        marginTop: "0.5rem",
        height: 64,
        marginLeft: "15rem",
      },
    },
  },
};
