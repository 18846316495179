import { StyledBox, StyledLink } from "./Footer.styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export function Footer(props) {
  const { children, ...other } = props;

  return (
    <StyledBox>
      <StyledLink href="https://www.login.gov/help/" variant="subtitle1">
        Help <OpenInNewIcon fontSize="small" />
      </StyledLink>
      <StyledLink href="https://www.login.gov/contact/" variant="subtitle1">
        Contact <OpenInNewIcon fontSize="small" />
      </StyledLink>
      <StyledLink href="https://www.login.gov/policy/" variant="subtitle1">
        Privacy & security <OpenInNewIcon fontSize="small" />
      </StyledLink>
    </StyledBox>
  );
}
