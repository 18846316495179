/**
 * Ssn phone page Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";

export const ssnPhoneStyles = {
  confirmInfoText: {
    fontSize: "2rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  ssnPhoneText: {
    fontSize: "2rem",
    fontFamily: "Oswald",
    color: "#1d3557",
  },
  confirmInfoSubText: {
    fontSize: "1.5rem",
    fontFamily: "Oswald",
    color: "#8d99ae",
  },
  modalTextFieldText: {
    color: "black",
    fontFamily: "Oswald",
    fontSize: "1rem",
    paddingLeft: "2rem",
  },
};

export const SSNPhonePageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
}));

export const SubmitSSNPhoneButton = styled(Button)(({ theme }) => ({
  color: "white",
  width: "17rem",
  height: "7rem",
  backgroundColor: "#00b4d8",
  "&:hover": {
    backgroundColor: "#90e0ef",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));

export const SsnPhoneTextField = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    // position: "relative",
    backgroundColor: "transparent",
    border: "0.5px solid #ced4da",
    width: "12rem",
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Oswald", "sans-serif"].join(","),
    "&:focus": {
      boxShadow: `black`,
      borderColor: "#ced4da",
    },
  },
}));
