/**
 * Avatar, Name, Email styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//library imports
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
export const AvatarItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
}));

export const AvatarNameItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "#013a63",
  height: "1.25rem",
  fontWeight: 595,
  fontSize: "1.25rem",
  boxShadow: "none",
}));

export const AvatarEmailItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  height: "0.5rem",
  color: "#7d8597",
  fontSize: ".90rem",
  boxShadow: "none",
}));
