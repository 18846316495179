/**
 * Navigation Drawer Component
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */

//library imports
import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useNavigate } from "react-router-dom";
//icons
import HomeIcon from "@mui/icons-material/Home";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import ArticleIcon from "@mui/icons-material/Article";
import LogoutIcon from "@mui/icons-material/Logout";
//files
import tuMiniLogo from "../../images/tuMiniLogo.png";
//styles
import { navigationStyles } from "./NavigationDrawer.styles";
import styles from "./NavigationDrawer.module.css";
//context
import { FlowContext } from "../../context/FlowContext";
import { LoginContext } from "../../context/LoginContext";
//services
import { signOut } from "../../services/Authentication";

const NavigationDrawer = () => {
  const { resetLogin } = useContext(LoginContext);
  const { updateSelectedFlow } = useContext(FlowContext);
  const navigate = useNavigate();
  const homeIconClicked = () => {
    navigate("../Dashboard", { replace: true });
    updateSelectedFlow({});
  };

  const flowIconClicked = () => {
    updateSelectedFlow({});
    navigate("../Flows", { replace: true });
  };

  const docIconClicked = () => {
    navigate("../Docs", { replace: true });
  };

  const logoutClicked = () => {
    resetLogin();
    navigate("/", { replace: true });
    signOut();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={navigationStyles.drawerStyle}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem sx={{ textAlign: "center" }} key="logo_icon_nav">
            <ListItemIcon sx={navigationStyles.logo}>
              <img className={styles.tuLogo} src={tuMiniLogo} alt="logo" />
            </ListItemIcon>
          </ListItem>
          <ListItem sx={{ textAlign: "center" }} button key="home_icon_nav">
            <ListItemIcon sx={navigationStyles.icons} onClick={homeIconClicked}>
              <HomeIcon sx={{ fontSize: "2rem", color: "#ced4da" }} />
            </ListItemIcon>
          </ListItem>
          <ListItem button key="flow_icon_nav">
            <ListItemIcon sx={navigationStyles.icons} onClick={flowIconClicked}>
              <AllInclusiveIcon sx={{ fontSize: "2rem", color: "#ced4da" }} />
            </ListItemIcon>
          </ListItem>
          <ListItem button key="doc_icon_nav">
            <ListItemIcon sx={navigationStyles.icons} onClick={docIconClicked}>
              <ArticleIcon sx={{ fontSize: "2rem", color: "#ced4da" }} />
            </ListItemIcon>
          </ListItem>
          <ListItem sx={{ marginTop: "16rem" }} button key="logout_icon_nav">
            <ListItemIcon sx={navigationStyles.icons} onClick={logoutClicked}>
              <LogoutIcon sx={{ fontSize: "2rem", color: "#ced4da" }} />
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};
export default NavigationDrawer;
