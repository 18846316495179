/**
 * Confirm Info Table Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import { styled } from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: "1rem",
    backgroundColor: "rgba(202, 240, 248, 0.4)",
    marginBottom: "1rem",
    paddingLeft: "18rem",
  },
}));

export const confirmInfoTableStyles = {
  cellStyle: {
    borderBottom: "none",
    fontFamily: "Oswald",
    fontSize: "1.25rem",
    color: "#1e6091",
  },
  cellStyleRowName: {
    fontSize: "1.25rem",
    borderBottom: "none",
    fontFamily: "Oswald",
    color: "#1e6091",
    fontWeight: 570,
  },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    background: "transparent !important",
  },
};
