/**
 * Welcome Page
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
//library imports
import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
//mui icons
import AccountCircle from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import KeyIcon from "@mui/icons-material/Key";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//styling
import {
  LoginItem as Item,
  PasswordItem,
  LoginButtonItem,
} from "./Login.styles";
import { LoginUsernamePasswordInputField } from "./Login.styles";
import { loginStyles } from "./Login.styles";
import { LoginButton } from "./Login.styles";
//global context
import { LoginContext } from "../../context/LoginContext";
//services
import { signIn } from "../../services/Authentication";

export const Login = ({ updateAttemptedUsername, updateAttemptedPassword }) => {
  const {
    updateUserMustChangePassword,
    updateUserMustEnterOTP,
    updateCurrentUserObject,
    updateShowLoginPopUp,
    updateUserPhoneNumber,
    updateUserLoggedIn,
  } = useContext(LoginContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePass = (event) => {
    let newPassword = event.target.value;
    setPassword(newPassword);
  };
  const handleChangeUsername = (event) => {
    let newUsername = event.target.value;
    setUsername(newUsername);
  };

  const loginButtonClicked = async () => {
    updateAttemptedUsername(username);
    updateAttemptedPassword(password);
    let currUser = await signIn(username, password);
    if (currUser !== null) {
      switch (currUser.challengeName) {
        case "NEW_PASSWORD_REQUIRED":
          updateUserMustChangePassword();
          updateUserPhoneNumber(
            currUser.challengeParam.userAttributes.phone_number
          );
          break;
        case "SMS_MFA":
          updateCurrentUserObject(currUser);
          updateUserMustEnterOTP();
          break;
        default:
          updateCurrentUserObject(currUser);
          updateUserLoggedIn();
      }
    } else {
      console.log("sorry wrong password");
      updateShowLoginPopUp(true);
    }
  };

  return (
    <Box alignItems="center" justify="center" sx={{ paddingTop: "2rem" }}>
      <Item>
        <FormControl variant="outlined">
          <LoginUsernamePasswordInputField
            id="outlined-adornment-username"
            type="text"
            value={username}
            onChange={(event) => handleChangeUsername(event)}
            sx={loginStyles.usernamePasswordText}
            startAdornment={
              <InputAdornment
                position="start"
                sx={loginStyles.usernamePasswordIcons}
              >
                <AccountCircle />
              </InputAdornment>
            }
          />
        </FormControl>
      </Item>
      <PasswordItem>
        <FormControl variant="outlined">
          <LoginUsernamePasswordInputField
            color="secondary"
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleChangePass}
            sx={loginStyles.usernamePasswordText}
            startAdornment={
              <InputAdornment
                position="start"
                sx={loginStyles.usernamePasswordIcons}
              >
                <KeyIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={loginStyles.usernamePasswordIcons}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </PasswordItem>
      <LoginButtonItem>
        <LoginButton variant="contained" onClick={loginButtonClicked}>
          Login <ArrowForwardIcon />
        </LoginButton>
      </LoginButtonItem>
    </Box>
  );
};
