/**
 * Play Flow Modal Styling
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export const playFlowModalStyles = {
  playButton: {
    fontSize: "6.5rem",
    "&:hover": {
      color: "lightBlue",
      cursor: "pointer",
    },
  },
  deleteFlowButton: {
    background: "#d62839",
    fontSize: "1.75rem",
    fontFamily: "Oswald",
    width: "28rem",
    color: "white",
    "&:hover": {
      background: "#023047",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
  },
};
export const DetailedFlowPageItemAddFlow = styled(Paper)(() => ({
  backgroundColor: "transparent",
  textAlign: "right",
  width: "10vw",
  marginLeft: "3rem",
  color: "#013a63",
  boxShadow: "none",
}));
