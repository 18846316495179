import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";

export const SUCCESS_PATH = "success";
export const FAIL_OTP_PATH = "fail_otp";
export const FAIL_ID_SELFIE_PATH = "fail_id_selfie";
/**
 * @param customStyleSuccess: {height: x, width:x, color: x, marginLeft:x}
 * @param customStyleFail: {height: x, width:x, color: x, marginLeft: x}
 */
export const getSuccessPaths = (customStyleSuccess, customStyleFail) => {
  return [
    {
      name: "Successful Verification",
      icon: <CheckCircleIcon sx={customStyleSuccess} />,
      value: SUCCESS_PATH,
    },
    {
      name: "Failed Verification (OTP)",
      icon: <DoNotDisturbOnIcon sx={customStyleFail} />,
      value: FAIL_OTP_PATH,
    },
    {
      name: "Failed Verification (ID/Selfie)",
      icon: <DoNotDisturbOnIcon sx={customStyleFail} />,
      value: FAIL_ID_SELFIE_PATH,
    },
  ];
};

export const getFlowType = () => {
  return [
    { name: "Document Verification Only", value: 1 },
    { name: "Identity Verification Only", value: 2 },
    { name: "One Time Passcode (OTP) Only", value: 3 },
    { name: "Document Verification, Identity Verification, OTP", value: 4 },
    { name: "Identity Verification and OTP", value: 5 },
  ];
};
