import { Box, Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Item = styled(Box)(({ theme }) => ({
    textAlign: 'center',
}));


export const SignInButton = styled(ToggleButton)(({theme}) => ({
    width: "200px",
}));