/**
 * login styles
 *
 * @copyright Public Sector, Transunion LLC
 * @author Misae Evans (misae.evans@transunion.com)
 *
 */
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

export const loginStyles = {
  formControl: { width: "50ch", margin: "1" },
  usernamePasswordIcons: {
    color: "white",
  },
  usernamePasswordText: {
    color: "white",
    fontFamily: "Oswald",
  },
};

export const LoginItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
}));
export const PasswordItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
  marginLeft: "2rem",
}));

export const LoginButtonItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  paddingTop: "0.75rem",
  textAlign: "center",
  color: "white",
  fontFamily: "Oswald, sans-serif;",
  boxShadow: "none",
  paddingLeft: "2rem",
}));

export const LoginUsernamePasswordInputField = styled(InputBase)(
  ({ theme }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "transparent",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: ["Oswald", "sans-serif"].join(","),
      "&:focus": {
        boxShadow: `white`,
        borderColor: "white",
      },
    },
  })
);

export const LoginButton = styled(Button)(({ theme }) => ({
  color: "white",
  height: "2.5rem",
  marginTop: "2rem",
  width: "20rem",
  backgroundColor: "#ffe169",
  "&:hover": {
    backgroundColor: "#fecf3e",
  },
  fontFamily: ["Oswald", "sans-serif"].join(","),
  fontSize: "1.5rem",
}));
